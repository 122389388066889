<template>
  <div id="form-demo">
    <div class="widget-container">
      <form
          action="your-action"
          @submit="handleSubmit"
      >
        <DxForm
            v-model:form-data="user"
            :read-only="false"
            @initialized="saveFormInstance"
            :show-validation-summary="true"
            validation-group="customerData"
        >
          <DxGroupItem>
            <DxSimpleItem
                :editor-options="passwordOptions"
                data-field="Password"
                editor-type="dxTextBox"
            >
              <DxLabel :visible="false"></DxLabel>
              <DxRequiredRule message="Gebe ein Passwort ein"/>
              <DxPatternRule pattern="^(?=.*[a-zA-Z])(?=.*\d).{8,}$"
                             message="Bitte wählen Sie ein Passwort, das mindestens 8 Zeichen lang ist und sowohl einen Buchstaben (Groß- oder Kleinbuchstabe) als auch eine Zahl enthält"/>
            </DxSimpleItem>
            <DxSimpleItem
                name="ConfirmPassword"
                data-field="ConfirmPassword"
                :editor-options="confirmPasswordOptions"
                editor-type="dxTextBox"
            >
              <DxLabel :visible="false"></DxLabel>
              <DxRequiredRule message="Gebe ein zweites Passwort ein"/>
              <DxPatternRule pattern="^(?=.*[a-zA-Z])(?=.*\d).{8,}$"
                             message="Bitte wählen Sie ein Passwort, das mindestens 8 Zeichen lang ist und sowohl einen Buchstaben (Groß- oder Kleinbuchstabe) als auch eine Zahl enthält"/>
              <DxCompareRule
                  :comparison-target="passwordComparison"
                  message="Die Passwörter stimmen nicht überein"
              />
            </DxSimpleItem>
          </DxGroupItem>
          <DxButtonItem
              :button-options="buttonOptions"
              horizontal-alignment="center"
          />
        </DxForm>
      </form>
    </div>
  </div>
</template>
<script>
import DxForm, {
  DxButtonItem,
  DxCompareRule,
  DxGroupItem,
  DxLabel,
  DxPatternRule,
  DxRequiredRule,
  DxSimpleItem
} from 'devextreme-vue/form';

import {useUserStore} from "@/stores/UserStore";
import auth from "@/auth";
import notify from "devextreme/ui/notify";

export default {
  components: {
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxPatternRule,
    DxRequiredRule,
    DxCompareRule,
    DxForm,
  },

  setup(){
    const userStore = useUserStore();
    return {userStore};
  },

  data() {
    return {
      formInstance: null,
      user: {
        Password: '',
        ConfirmPassword: '',
      },
      buttonOptions: {
        text: 'Passwort zurücksetzen',
        type: 'success',
        useSubmitBehavior: true,
      },
      passwordOptions: {
        stylingMode: 'filled',
        placeholder: 'Passwort',
        mode: 'password',
      },
      confirmPasswordOptions: {
        stylingMode: 'filled',
        placeholder: 'Passwort wiederholen',
        mode: 'password',
      },
    };
  },
  methods: {
    saveFormInstance(e) {
      this.formInstance = e.component;
    },
    passwordComparison() {
      return this.user.Password;
    },
    async handleSubmit(e) {
      e.preventDefault();
      const result = await this.userStore.changePassword(this.user.Password);

      if(result.status === 0){
        await auth.logOut();
        notify("Passwort erfolgreich zurückgesetzt, melde dich jetzt neu an!", "success", 5000);
      } else {
        notify("Passwort konnte nicht zurückgesetzt werden!", "error", 5000);
      }
    },
  },
};
</script>
<style scoped>
</style>