<template>
  <div>
    <h2 class="content-block">Verfügbarkeit</h2>

    <DxDataGrid
        class="dx-card wide-card"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
        @cell-click="cellClickedEventHandler"
    >
      <DxFilterRow :visible="true"/>
      <DxHeaderFilter :visible="true"/>

      <DxToolbar>
        <DxItem location="before" locate-in-menu="auto">
          <DxSelectBox
              ref="selectBoxOptionsArtikelGruppenComponent"
              width="180"
              label="Artikelgruppe"
              labelMode="floating"
              placeholder="Wähle eine Artikelgruppe"
              :value="availabilityStore.selectedArticleGroup"
              :data-source="artikelGruppenSelectBoxDataSource"
              @value-changed="selectBoxArtikelGruppenValueChangedEvent"
          />
        </DxItem>

        <DxItem locate-in-menu="auto">
          <div style="padding:10px;"> Letzte Aktualisierung <br> {{availabilityStore.lastUpdatedDate}}</div>
        </DxItem>

        <DxItem location="after" locate-in-menu="auto">
          <DxButton :width="150" text="Aktualisieren" type="normal" styling-mode="outlined" @click="refreshClicked"/>
        </DxItem>

      </DxToolbar>

      <DxEditing mode="cell" :allow-adding="false" :allow-deleting="false" :allow-updating="false"/>

      <dx-paging :page-size="10"/>
      <dx-pager :visible="true" :allowed-page-sizes="pageSizes"
                :show-navigation-buttons="true" :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="true"/>

      <DxColumn data-field="name" caption="Bezeichnung" data-type="string" width="220"
                :allow-editing="false"/>
      <dx-column data-field="artikelgruppe" caption="Artikelgruppe" data-type="string" :allow-editing="false"
                 :visible="false"/>

      <template #availabilityCellTemplate="{ data }">
        <availabilityColorDivTemplate :item-data="data" :index="extractIndex(data.column.dataField)"/>
      </template>

      <dx-column
          :data-field="getKwsField(currentCalendarWeek())"
          :caption="'KW ' + currentCalendarWeek()"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(1))"
          :caption="'KW ' +  currentCalendarWeek(1)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(2))"
          :caption="'KW ' + currentCalendarWeek(2)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(3))"
          :caption="'KW ' + currentCalendarWeek(3)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(4))"
          :caption="'KW ' + currentCalendarWeek(4)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(5))"
          :caption="'KW ' + currentCalendarWeek(5)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(6))"
          :caption="'KW ' + currentCalendarWeek(6)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(7))"
          :caption="'KW ' + currentCalendarWeek(7)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

    </DxDataGrid>
  </div>
</template>

<script>

import availabilityColorDivTemplate from "../templates/availability-page-templates/availibilityColorDivTemplate.vue";

import DxButton from "devextreme-vue/button";
import DataSource from "devextreme/data/data_source";
import {DxSelectBox} from "devextreme-vue/select-box";
import notify from "devextreme/ui/notify";
import Utils from "../../utils/util";
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxItem,
  DxPager,
  DxPaging,
  DxToolbar,
} from "devextreme-vue/data-grid";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useAvailabilityCustomerStore} from "@/stores/AvailabilityCustomerStore";

/**
 * Reference Keys für die UI Komponenten
 */

const popupComponent = "";
const orderDataGridComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    availabilityColorDivTemplate,
    DxDataGrid,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxItem,
    DxPager,
    DxButton,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  setup() {
    const availabilityStore = useAvailabilityCustomerStore();
    const companyStore = useCompanyStore();

    return {availabilityStore, companyStore}
  },

  async mounted() {
    if (this.availabilityStore.originalArticleAvailable.length <= 0) {
      await this.availabilityStore.fetchArticle();
      await this.refreshGridAndData();
    }
  },

  data() {

    var isPopupVisible = false;
    var selectedColumnData = [];
    var parentData = {};
    const pageSizes = [5, 10, 20, 'all'];

    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "name",
      load: () => {
        console.log(this.availabilityStore.displayGridData)
        return this.availabilityStore.displayGridData;
      },
    });

    const artikelGruppenSelectBoxDataSource = new DataSource({
      load: () => {
        return this.availabilityStore.articleGroups
      },
      byKey: () => {
        return this.availabilityStore.selectedArticleGroup
      },
    });

    /**
     * Current Calendar Week
     */

    const currentCalendarWeek = function (weeksToAddToCounter = null, onlyNumber = true, givenDate = null) {
      return Utils.getCalendarWeek(weeksToAddToCounter, onlyNumber, givenDate);
    };

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      popupComponent,
      selectedColumnData,
      isPopupVisible,
      pageSizes,
      parentData,
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      artikelGruppenSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsArtikelGruppenComponent,

      /**
       * Export UserData an die View Komponente
       */
      userData,

      /**
       * Export Additional Data to the view component
       */

      currentCalendarWeek,
    };
  },
  methods: {

    extractIndex(dataField) {
      return parseInt(dataField.split('.')[1], 10);
    },

    getKwsField(index) {
      return `kws.${index}`;
    },

    async refreshGridAndData() {

      try {
        if (this.availabilityStore.originalArticleAvailable.length <= 0) {
          notify("Daten können nicht geladen werden", "error", 5000);
          this.orderDataGridInstance.endCustomLoading();
        }
      } catch (error) {
        this.orderDataGridInstance.endCustomLoading();
        throw error;
      }
      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();

    },

    async refreshClicked() {
      try {
        this.orderDataGridInstance.beginCustomLoading();
        await this.availabilityStore.fetchArticle();
        this.orderDataGridInstance.refresh();
        this.orderDataGridInstance.endCustomLoading();

        notify("Daten aktualisiert!", "success", 3000);
      } catch (error) {
        notify(error, "error", 5000);
        this.orderDataGridInstance.endCustomLoading();
      }
    },

    selectBoxArtikelGruppenValueChangedEvent(item) {
      this.availabilityStore.selectedArticleGroup = item.value;
      this.orderDataGridInstance.refresh();
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
  },
};
</script>