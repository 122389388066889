import {useApplicationStore} from "@/stores/ApplicationStore";
import {useUserStore} from "@/stores/UserStore";
import {defineStore} from "pinia";
import axios from "axios"
import Util from "@/utils/util";
import auth from "@/auth";

export const useSupplierStore = defineStore('SupplierStore', {

    state: () => {

        const originalTourData: any[] = [];
        const article: string = 'Alle Artikel';
        const selectedTour: any = 1;
        const lastUpdatedDate: any = null;

        return {
            originalTourData,
            selectedTour,
            article,
            lastUpdatedDate
        };
    },

    actions: {

        async fetchArticlebyTourId() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();

            console.log(this.selectedTour);

                const data = await axios.post(applicationStore.getApiUrl + 'spShopLWLieferant', {
                    tourID: this.selectedTour.tourID,
                }, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            this.lastUpdatedDate = new Date().toLocaleString('de-DE');
            this.article = data.data;

        },

        async fetchTourData() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try{
                const data = await axios.get(applicationStore.getApiUrl + 'vwShopTourLieferant', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.originalTourData = data.data;

            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },
    },

    getters: {
        getLastUpdatedDate(): string{
            if(this.lastUpdatedDate !== null){
                return this.lastUpdatedDate;
            } else {
                return "";
            }
        },
    },

    persist: true
});
