<template>
  <div>
    <h2 class="content-block">Bestellen</h2>

    <DxDataGrid
        class="dx-card wide-card"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :no-data-text="gridNoDataText"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
        @cell-prepared="onCellPrepared"
        @editing-start="onEditingStart"
        @row-validating="onCellValidating"
    >
      <DxFilterRow :visible="true"/>
      <DxHeaderFilter :visible="true"/>

      <DxToolbar>
        <DxItem location="before" locate-in-menu="auto">
          <DxSelectBox
              ref="selectBoxOptionsTourComponent"
              label="Tourenauswahl"
              width="230"
              labelMode="floating"
              placeholder="Wähle eine Tour"
              :value="orderStore.selectedTour"
              :data-source="tourSelectBoxDataSource"
              :display-Expr="selectBoxTourDisplayExpression"
              @value-changed="selectBoxTourValueChangedEvent"
              item-template="tourSelectBoxTemplate"
          >
            <template #tourSelectBoxTemplate="{ data }">
              <tourSelectBoxItemTemplate :item-data="data"/>
            </template>
          </DxSelectBox>
        </DxItem>

        <DxItem location="before" locate-in-menu="auto">
          <DxSelectBox
              ref="selectBoxOptionsArtikelGruppenComponent"
              width="180"
              label="Artikelgruppe"
              labelMode="floating"
              placeholder="Wähle eine Artikelgruppe"
              :value="orderStore.selectedArticleGroupData"
              :data-source="artikelGruppenSelectBoxDataSource"
              @value-changed="selectBoxArtikelGruppenValueChangedEvent"
          />
        </DxItem>

        <DxItem locate-in-menu="auto">
          <div style="padding:10px;"> Letzte Aktualisierung <br> {{ orderStore.lastUpdatedDate }}</div>
        </DxItem>

        <DxItem location="after" locate-in-menu="auto">
          <DxButton :width="150" text="Aktualisieren" type="normal" styling-mode="outlined" @click="refreshClicked"/>
        </DxItem>

        <DxItem locate-in-menu="auto">
          <DxButton width="240" ref="buttonWarenkorb" :text="basketText" type="success" styling-mode="outlined"
                    @click="warenkorbToggle"/>
        </DxItem>

        <DxItem locate-in-menu="auto">
          <DxButton width="180" text="Bestellen" type="danger" styling-mode="outlined" @click="finishOrder"/>
        </DxItem>
      </DxToolbar>

      <DxEditing mode="cell" :allow-adding="false" :allow-deleting="false" :allow-updating="true"/>

      <dx-paging :page-size="10"/>
      <dx-pager :visible="true" :allowed-page-sizes="pageSizes"
                :show-navigation-buttons="true" :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="true"/>

      <dx-column data-field="artikelnummer" caption="Artikelnummer" data-type="string" :allow-editing="false"
                 :visible="false"/>

      <DxColumn data-field="artikelbezeichnung" caption="Bezeichnung" data-type="string" width="280"
                :allow-editing="false"/>

      <dx-column
          data-field="producer"
          caption="Produzent"
          data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="producerCustomTemplate"
          :width="240"
      ></dx-column>

      <template #producerCustomTemplate="{ data }">
        <producerSelectBoxTemplate :item-data="data.data.produzenten" :parent-data="parentData"
                                   :selected-producer="data.data.selectedProducerID"/>
      </template>

      <dx-column data-field="artikelgruppe" caption="Artikelgruppe" data-type="string" :allow-editing="false"
                 :visible="false"/>

      <dx-column
          data-field="kw1"
          :caption="currentCalendarWeek()"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          width="105"
          cell-template="availibilityCellTemplate"
      />

      <template #availibilityCellTemplate="{ data }">
        <availibilityColorDivTemplate :item-data="data"/>
      </template>

      <dx-column
          data-field="kw2"
          :caption="currentCalendarWeek(1)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          width="105"
          cell-template="availibilityCellTemplate"
      />

      <dx-column
          data-field="kw3"
          :caption="currentCalendarWeek(2)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          width="105"
          cell-template="availibilityCellTemplate"
      />

      <dx-column
          data-field="mengeProVPE"
          caption="Inhalt (VPE)"
          data-type="number"
          width="100"
          :allow-editing="false"
          :calculate-cell-value="mengeVPECellFunction"
      />

      <dx-column
          data-field="eingabeBestellMenge"
          caption="Eingabe (VPE)"
          data-type="number"
          :allow-editing="true"
          :allow-updating="true"
          :validation-rules="eingabeBestellMengeValidationRules"
          cell-template="enterOrderAmountVPE"
      />

      <template #enterOrderAmountVPE="{ data }">
        <enter-order-amount-v-p-e @update-grid-on-load="refreshGridAndData" :item-data="data.data" :row-data="data"
                                  :parent-data="parentData"/>
      </template>

      <dx-column
          data-field="halfVPE"
          caption="Halbe VPE"
          data-type="string"
          width="80"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="halfVPECellTemplate"
      ></dx-column>

      <template #halfVPECellTemplate="{ data }">
        <amountVPECheckBoxTemplate @update-grid-on-price-update="refreshGridAndData" :item-data="data.data"
                                   :row-data="data" :parent-data="parentData"/>
      </template>

      <dx-column
          data-field="bestellMengeStueck"
          caption="Bestellmenge VPE"
          data-type="string"
          :allow-editing="false"
          :calculate-cell-value="eingabebestellmengeCellFunction"
      />

      <dx-column data-field="bestellmenge" caption="Bestellte VPE" data-type="string" :allow-editing="false"/>

      <dx-column data-field="preis" caption="Einzelpreis" data-type="string" width="160" :allow-editing="false"
                 :calculate-cell-value="preisCellFunction"/>

      <DxSummary :calculate-custom-summary="sumPrice">
        <DxTotalItem name="sumPrice" :display-format="roundSumme" summary-type="custom" show-in-column="preis"/>
      </DxSummary>
    </DxDataGrid>
  </div>
</template>

<script>
import tourSelectBoxItemTemplate from "../templates/order-page-templates/orderTourSelectBoxItemTemplate.vue";
import producerSelectBoxTemplate from "../templates/order-page-templates/producerSelectBoxTemplate.vue";
import amountVPECheckBoxTemplate from "../templates/order-page-templates/amountVPECheckBoxTemplate.vue";
import availibilityColorDivTemplate from "../templates/order-page-templates/orderAvailibilityColorDivTemplate.vue";
import enterOrderAmountVPE from "@/views/templates/order-page-templates/enterOrderAmountVPE.vue";

import DxButton from "devextreme-vue/button";
import DataSource from "devextreme/data/data_source";
import {DxSelectBox} from "devextreme-vue/select-box";
import notify from "devextreme/ui/notify";
import Utils from "../../utils/util";
import auth from "../../auth";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxItem,
  DxFilterRow,
  DxHeaderFilter,
  DxToolbar,
  DxTotalItem,
  DxSummary,
  DxEditing,
} from "devextreme-vue/data-grid";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useOrderStore} from "@/stores/OrderStore";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    tourSelectBoxItemTemplate,
    enterOrderAmountVPE,
    producerSelectBoxTemplate,
    amountVPECheckBoxTemplate,
    availibilityColorDivTemplate,
    DxDataGrid,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxSummary,
    DxTotalItem,
    DxItem,
    DxPager,
    DxButton,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  setup() {
    const orderStore = useOrderStore();
    const companyStore = useCompanyStore();

    return {orderStore, companyStore}
  },

  async mounted() {

    let newData = false;

    if (this.orderStore.originalTourData.length <= 0) {
      await this.orderStore.fetchTourData();
      newData = true;
    }

    if (this.orderStore.articleAndProducerData.length <= 0) {
      this.orderStore.selectedTour = this.orderStore.originalTourData[0];
      await this.orderStore.fetchArticleAndProducerData();
    }

    if (newData) {
      this.orderStore.selectedTour = this.orderStore.originalTourData[0];
      await this.refreshGridAndData();
    }
  },

  data() {

    const eingabeBestellMengeValidationRules = [
      {
        type: 'pattern',
        pattern: "^-?\\d+$",
        message: 'Nur ganze Zahlen sind erlaubt'
      },
      {
        type: 'pattern',
        pattern: "^[0-9]\\d*$",
        message: 'Nur positive Zahlen erlaubt'
      }
    ];


    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    var parentData = {};

    let pageSizes = [5, 10, 20, 'all'];

    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "pK_ID",
      load: () => {

        const currentDate = new Date();

        for (let i = 0; i < this.orderStore.articleAndProducerData.length; i++) {
          if (1 === this.orderStore.articleAndProducerData[i]?.verpackungsfaktorID) {

            for (let x = 0; x < this.orderStore.originalArticleAndProducerData.length; x++) {
              if (1 === this.orderStore.articleAndProducerData[x]?.verpackungsfaktorID) {

                if(this.orderStore.articleAndProducerData[i].mengeProVPE === this.orderStore.originalArticleAndProducerData[x].mengeProVPE){
                  this.orderStore.articleAndProducerData[i].mengeProVPE = this.orderStore.articleAndProducerData[i].mengeProVPE / 2;
                }
              }
            }
          }
        }

        const currentlySelectedTourKw = Utils.getCalendarWeek(null, true, this.tourSelectBoxInstance.option("value").datum);
        const currentCalendarWeek = Utils.getCalendarWeek(null, true, currentDate);
        const colorCodeIndexToCheck = (currentlySelectedTourKw - currentCalendarWeek) + 1;

        for (let i = 0; i < this.orderStore.articleAndProducerData.length; i++) {
          const article = this.orderStore.articleAndProducerData[i];

          switch (colorCodeIndexToCheck) {
            case 1:
              console.log(article.farbeVerfuegbarKWAktuell)
              if (this.isColorCodeLocked(article.farbeVerfuegbarKWAktuell)) {
                this.orderStore.articleAndProducerData[i].lockedEingabe = true;
              }
              break;
            case 2:
              if (this.isColorCodeLocked(article.farbeVerfuegbarKWPlus1)) {
                this.orderStore.articleAndProducerData[i].lockedEingabe = true;
              }
              break;
            case 3:
              if (this.isColorCodeLocked(article.farbeVerfuegbarKWPlus2)) {
                this.orderStore.articleAndProducerData[i].lockedEingabe = true;
              }
              break;
          }
        }

        return this.orderStore.displayGridData;
      },
      update: (key, values) => {

        for (let z = 0; z < this.orderStore.originalTourData.length; z++) {
          if (this.orderStore.originalTourData[z].tourID === this.tourSelectBoxInstance.option("value").tourID) {
            this.orderStore.originalTourData[z].kzBestellungenVorhanden = 2;
            break;
          }
        }
        this.tourSelectBoxInstance.repaint();

        for (let index = 0; index < this.orderStore.articleAndProducerData.length; index++) {
          if (this.orderStore.articleAndProducerData[index].pK_ID === key) {
            this.orderStore.articleAndProducerData[index].eingabeBestellMenge = values.eingabeBestellMenge;
            this.orderDataGridInstance.refresh();
            break;
          }
        }
      },
    });

    const tourSelectBoxDataSource = new DataSource({
      key: "tourID",
      load: () => {
        return this.orderStore.originalTourData;
      },
      byKey: () => {
        return this.orderStore.selectedTour
      },
    });

    const artikelGruppenSelectBoxDataSource = new DataSource({
      load: () => {
        return this.orderStore.originalArticleGroupData;
      },
      byKey: () => {
        return this.orderStore.selectedArticleGroupData
      },
    });

    const currentCalendarWeek = function (weeksToAddToCounter = null, onlyNumber = false, givenDate = null) {
      return Utils.getCalendarWeek(weeksToAddToCounter, onlyNumber, givenDate);
    };

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      pageSizes,
      delay,
      parentData,
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      tourSelectBoxDataSource,
      artikelGruppenSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
      userData,

      /**
       * Export Additional Data to the view component
       */

      currentCalendarWeek,

      /**
       * ValidatonRues
       */
      eingabeBestellMengeValidationRules,

    };
  },
  methods: {

    isColorCodeLocked(colorCode) {
      return colorCode !== "7FFF00";
    },

    onCellValidating(e) {
      if (e.brokenRules.length > 0) {
        e.isValid = false;
        e.cancel = true;
      }
    },

    roundSumme(e) {
      return "Bestellwert: " + parseFloat(e).toFixed(2) + " €";
    },

    onEditingStart(e) {

      if (e.column.dataField === "eingabeBestellMenge") {
        if (e.data.lockedEingabe === true) {
          e.cancel = true;
        }
      }

      if (e.data.locked === true) {
        e.cancel = true;
      }
    },

    async refreshGridAndData() {
      try {
        if (this.orderStore.originalTourData.length <= 0 || this.orderStore.articleAndProducerData.length <= 0) {
          notify("Daten können nicht geladen werden", "error", 5000);
          this.orderDataGridInstance.endCustomLoading();
        }
      } catch (error) {
        this.orderDataGridInstance.endCustomLoading();
        throw error;
      }

      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();
    },

    async refreshClicked(ordered = false) {
      try {
        this.orderDataGridInstance.beginCustomLoading();
        await this.orderStore.fetchTourData();
        await this.orderStore.fetchArticleAndProducerData();
        this.orderDataGridInstance.refresh();
        this.orderDataGridInstance.endCustomLoading();

        if (ordered === true) {
          notify("Bestellung ausgeführt!", "success", 3000);
        } else {
          notify("Daten aktualisiert!", "success", 3000);
        }

      } catch (error) {
        notify(error, "error", 5000);
        this.orderDataGridInstance.endCustomLoading();
      }
    },

    sumPrice(options) {
      if (options.value !== undefined) {
        if (options.name === "sumPrice") {
          if (options.summaryProcess === "start") {
            options.totalValue = 0;
          } else if (options.summaryProcess === "calculate") {

            for (let i = 0; i < this.orderStore.articleAndProducerData.length; i++) {
              if (this.orderStore.articleAndProducerData[i].pK_ID === options.value.pK_ID) {
                if (this.orderStore.articleAndProducerData[i].halbeVPEChecked) {
                  break;
                }
              }
            }

            const preis = parseFloat(options.value.preis);
            const bestellmenge = parseFloat(options.value.bestellmenge);
            const mengeProVPE = parseFloat(options.value.mengeProVPE);

            console.log("preis", preis);
            console.log("bestellmenge", bestellmenge);
            console.log("bestellmengeStueck", mengeProVPE);

            if (preis !== null && bestellmenge !== null && mengeProVPE !== null && !Number.isNaN(mengeProVPE) && !Number.isNaN(preis) && !Number.isNaN(bestellmenge)) {

              const multiply = mengeProVPE * bestellmenge * preis ;
              options.totalValue += multiply;
            }

            if (options.totalValue == null) {
              options.totalValue = 0;
            }
          }
        }
      }
      if (options.totalValue === undefined || Number.isNaN(options.totalValue)) {
        options.totalValue = 0;
      }
    },

    selectBoxTourDisplayExpression(item) {

      if (item !== null && item.datum) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }

        return (
            strWochentag +
            " " +
            item.datum.substring(8, 10) +
            "." +
            item.datum.substring(5, 7) +
            "." +
            item.datum.substring(0, 4) +
            " (KW " +
            this.currentCalendarWeek(null, true, item.datum) + ")"
        );
      }
    },

    onCellPrepared(e) {

      if (e.rowType == "data" && e.column.dataField == "eingabeBestellMenge") {
        e.cellElement.classList.add("eingabeUnderline");
      }

      if (e.rowType == "data" && e.column.dataField == "producer") {
        e.cellElement.classList.add("cellProducer");
      }

      if (e.rowType == "data" && e.column.dataField == "eingabeBestellMenge" && e.data.eingabeBestellMenge >= 0 && e.data.eingabeBestellMenge != null) {
        e.cellElement.classList.add("cellColorOrderEdit");
      }

      if (e.rowType == "data" && e.column.dataField == "bestellmenge" && e.data.bestellmenge > 0) {
        e.cellElement.classList.add("cellColorAlreadyOrderedAmount");
      }
    },

    async finishOrder() {

      await this.delay(500);

      //Check ob in der Tour daten eingegeben wurden
      this.orderDataGridInstance.beginCustomLoading();
      let orderArticel = false;
      for (let i = 0; i < this.orderStore.displayGridData.length; i++) {
        if (this.orderStore.displayGridData[i].eingabeBestellMenge >= 0) {
          orderArticel = true;
          break;
        }
      }

      if (orderArticel === false) {
        notify("Keine Artikel zum bestellen ausgewählt.", "warning", 3000);
        this.orderDataGridInstance.endCustomLoading();
        return;
      }

      // Eingegebene Daten als Objekt für das Abspeichern sichern.

      const orderArticelObject = [];

      for (let x = 0; x < this.orderStore.articleAndProducerData.length; x++) {
        if (this.orderStore.articleAndProducerData[x].eingabeBestellMenge >= 0) {

          // check ob ein Produzent schon bestellt hat

          const result = this.orderStore.articleAndProducerData[x].produzenten.filter(function (el) {
            return el.kzProduzentHatBestellung === 1;
          });

          let selectedProducerIndex = 0;
          let searchAdresseIDLieferant = null;

          if (result.length > 0) {
            searchAdresseIDLieferant = result[0].adresseIDLieferant;
          }

          if (searchAdresseIDLieferant === null) {

            if (this.orderStore.articleAndProducerData[x].selectedProducerID) {
              for (let k = 0; k < this.orderStore.articleAndProducerData[x].produzenten.length; k++) {
                if (this.orderStore.articleAndProducerData[x].produzenten[k].pK_ID === this.orderStore.articleAndProducerData[x].selectedProducerID) {
                  selectedProducerIndex = k;
                  break;
                }
              }
            }

            if (this.orderStore.articleAndProducerData[x].produzenten[selectedProducerIndex]?.kzProduzentIstBiohof !== 1) {
              searchAdresseIDLieferant = this.orderStore.articleAndProducerData[x].produzenten[selectedProducerIndex]?.adresseIDLieferant;
            }
          }

          console.log("bestellen Artikel Verpakungsfaktor ID ", this.orderStore.articleAndProducerData[x].verpackungsfaktorID);

          console.log("ID des Lieferanten ", searchAdresseIDLieferant);

          console.log("adresseidlieferant", searchAdresseIDLieferant);

          if (searchAdresseIDLieferant === 0) {
            searchAdresseIDLieferant = null;
          }

          orderArticelObject.push({
            pK_ID: this.orderStore.articleAndProducerData[x].pK_ID,
            tourID: this.orderStore.articleAndProducerData[x].tourID,
            adresseID: this.orderStore.articleAndProducerData[0].adresseID,
            artikelID: this.orderStore.articleAndProducerData[x].artikelID,
            verpackungsfaktorID: this.orderStore.articleAndProducerData[x].verpackungsfaktorID,
            adresseIDLieferant: searchAdresseIDLieferant,
            menge: this.orderStore.articleAndProducerData[x].eingabeBestellMenge,
          });
        }
      }

      //Schicke die Order Anfrage an die API

      try {
        for (const element of orderArticelObject) {
          if (element.tourID === this.tourSelectBoxInstance.option("value").tourID) {
            await this.orderStore.postOrder(element);
          }
        }

        await this.delay(1000);
        await this.refreshClicked(true);
        this.orderDataGridInstance.endCustomLoading();

      } catch (error) {
        notify("Fehler: " + error, "error", 3000);
        this.orderDataGridInstance.endCustomLoading();
      }
    },

    disableUIComponents() {
      this.artikelGruppenSelectBoxInstance.option("disabled", true);
    },

    enableUIComponents() {
      this.artikelGruppenSelectBoxInstance.option("disabled", false);
    },

    warenkorbToggle() {
      if (this.orderStore.basketToggle === false) {
        this.disableUIComponents();

        this.orderStore.selectedArticleGroupData = 'Alle Artikel'

        this.orderDataGridInstance.refresh();

        this.orderStore.basketToggle = true;
      } else {

        this.enableUIComponents();
        this.orderDataGridInstance.refresh();

        this.orderStore.basketToggle = false;
      }
    },

    /**
     * Value Change Events
     */

    selectBoxArtikelGruppenValueChangedEvent(item) {


      this.orderStore.selectedArticleGroupData = item.value;
      this.orderDataGridInstance.refresh();
    },

    async selectBoxTourValueChangedEvent(item) {
      this.orderDataGridInstance.beginCustomLoading();
      this.orderStore.selectedTour = item.value;

      await this.orderStore.fetchArticleAndProducerData();
      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();
    },

    /**
     * Get User Data
     */

    async getUserData() {
      await auth.getUser().then((e) => {
        this.userData = e.data;
      });
    },

    /**
     * Zell Funktionen der Grid Spalten
     */

    mengeVPECellFunction(rowData) {
      if (rowData.einheit !== "kg") {
        return Math.ceil(rowData.mengeProVPE) + " " + rowData.einheit;
      } else {
        return rowData.mengeProVPE + " " + rowData.einheit;
      }
    },

    eingabebestellmengeCellFunction(rowData) {

      if (rowData.eingabeBestellMenge > 0 && rowData.mengeProVPE > 0) {
        let result = rowData.eingabeBestellMenge * rowData.mengeProVPE;
        if (rowData.einheit !== "kg") {
          result = Math.ceil(result);
        }
        return result + " " + rowData.einheit;
      } else if (typeof rowData.eingabeBestellMenge === "undefined" || rowData.eingabeBestellMenge === 0) {
        return "0 " + rowData.einheit;
      } else {
        return "0 " + rowData.einheit;
      }


    },
    preisCellFunction(rowData) {

      let einzelpreis = parseFloat(rowData.preis);
      return String(einzelpreis.toFixed(2)) + "€";
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
    artikelGruppenSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsArtikelGruppenComponent.instance;
    },
    buttonWarenkorbInstance: function () {
      return this.$refs.buttonWarenkorb.instance;
    },
    gridNoDataText() {
      if (this.orderStore.basketToggle) {
        return "Sie haben noch keine Artikel im Warenkorb";
      } else {
        return "Keine Artikel gefunden!";
      }
    },
    basketText() {
      if (this.orderStore.basketToggle) {
        return 'Artikelübersicht';
      } else {
        return 'Warenkorbansicht';
      }
    },
  },
};
</script>

<style>
.cellColorAlreadyOrderedAmount {
  background-color: #dcecca !important;
}

.cellColorOrderEdit {
  background-color: #ffffe0 !important;
}

.cellProducer {
  padding: 0px !important;
  width: 100%;
}

.eingabeUnderline {
  border-bottom: 1.5px solid black !important;
}
</style>
