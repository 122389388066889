import {useApplicationStore} from "@/stores/ApplicationStore";
import {useUserStore} from "@/stores/UserStore";
import {defineStore} from "pinia";
import axios from "axios"
import Util from "@/utils/util";
import auth from "@/auth";

export const useOfferStore = defineStore('OfferStore', {

    state: () => {

        const originalTourData: any[] = [];
        const articleData: any[] = [];
        const originalArticleGroupData: any[] = [];
        const selectedArticleGroupData: string = 'Alle Artikel';
        const selectedTour: TourModel = {tourID: null}
        const basketToggle: boolean = false;
        const lastUpdatedDate: any = null;

        return {
            originalTourData,
            articleData,
            selectedArticleGroupData,
            originalArticleGroupData,
            selectedTour,
            basketToggle,
            lastUpdatedDate
        }
    },


    actions: {

        async fetchArticleData() {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();

            if (this.selectedTour === null) {
                throw new Error('no tour selected')
            }
            try{
                const data = await axios.get(applicationStore.getApiUrl + 'vwShopLWAnbieten/' + this.selectedTour.tourID, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })

                    this.articleData = data.data
                    this.lastUpdatedDate = new Date().toLocaleString('de-DE');
                    this.setArticleGroups();
                    console.log("article", data.data);
            }catch (error: any) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                await auth.logOut();
            } else {
                throw error.response.status + " http error";
            }
        }
        },

        async fetchTourData() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try{
                const data = await axios.get(applicationStore.getApiUrl + 'vwShopTour', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.originalTourData = data.data;
                console.log("TourID", this.originalTourData[0]);
            }catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },

        async postOffer(body: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try{
                const data = await axios.post(applicationStore.getApiUrl + 'spShopLWAnbieten', {
                    tourID: body.tourID, adresseID: body.adresseID, artikelID: body.artikelID, menge: body.menge,
                }, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });
            }catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }

        },

        setArticleGroups() {
            const articleGroups = ["Alle Artikel"];
            for (let i = 0; i < this.articleData.length; i++) {
                if (!articleGroups.includes(this.articleData[i].artikelgruppe)) {
                    articleGroups.push(this.articleData[i].artikelgruppe);
                }
            }
            this.originalArticleGroupData = articleGroups;
        },
    },



    getters: {
        getLastUpdatedDate(): string{
            if(this.lastUpdatedDate !== null){
                return this.lastUpdatedDate;
            } else {
                return "";
            }
        },

        displayGridData(): any[] {

            if (this.articleData.length <= 0) {
                return [];
            }

            let ret = Util.filterGridNachArtikelGruppeUndTour(this.articleData, this.selectedArticleGroupData, null);

            console.log("basketStatus", this.basketToggle);

            if (this.basketToggle) {
                ret = ret.filter(function (el) {
                    return el.anbietenMenge > 0 || el.eingabeAnbietenMenge > 0;
                });
            }
            return ret;
        },
    },


    persist: true,
});
