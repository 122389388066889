<template>
  <div>
    <h2 class="content-block">Verfügbarkeit</h2>

    <DxPopup id="popup"
             class="popup-availability"
             ref="popupComponent"
             content-template="popup-content"
             v-model:visible="isPopupVisible"
             :resize-enabled="true"
             :show-title="true"
             title="Mengen Informationen"
             showCloseButton="true"
             :hide-on-outside-click="true">

      <template #popup-content>

        <DxForm
            id="form"
            :form-data="showSelectedColumnData"
            label-mode="outside"
            show-colon-after-label="true"
            label-location="top"
            min-col-width="100"

        >
              <DxGroupItem :col-count="2">
                <DxItem data-field="mengeIst"/>
                <DxItem
                    data-field="availability"
                    :editor-type="dxSwitch"
                >
                  <DxLabel text="Verfügbarkeit"/>
                </DxItem>
              </DxGroupItem>
        </DxForm>

        <DxButton width="180" text="Speichern" type="danger" styling-mode="outlined" @click="saveClicked"/>
      </template>
    </DxPopup>

    <DxDataGrid
        class="dx-card wide-card"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
        @cell-click="cellClickedEventHandler"
    >
      <DxFilterRow :visible="true"/>
      <DxHeaderFilter :visible="true"/>

      <DxToolbar>
        <DxItem location="before" locate-in-menu="auto">
          <DxSelectBox
              ref="selectBoxOptionsArtikelGruppenComponent"
              width="180"
              label="Artikelgruppe"
              labelMode="floating"
              placeholder="Wähle eine Artikelgruppe"
              :value="availabilityStore.selectedArticleGroup"
              :data-source="artikelGruppenSelectBoxDataSource"
              @value-changed="selectBoxArtikelGruppenValueChangedEvent"
          />
        </DxItem>

        <DxItem locate-in-menu="auto">
          <div style="padding:10px;"> Letzte Aktualisierung <br> {{availabilityStore.lastUpdatedDate}}</div>
        </DxItem>

        <DxItem location="after" locate-in-menu="auto">
          <DxButton :width="150" text="Aktualisieren" type="normal" styling-mode="outlined" @click="refreshClicked"/>
        </DxItem>

      </DxToolbar>

      <DxEditing mode="cell" :allow-adding="false" :allow-deleting="false" :allow-updating="false"/>

      <dx-paging :page-size="10"/>
      <dx-pager :visible="true" :allowed-page-sizes="pageSizes"
                :show-navigation-buttons="true" :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="true"/>

      <DxColumn data-field="name" caption="Bezeichnung" data-type="string" width="220"
                :allow-editing="false"/>
      <dx-column data-field="artikelgruppe" caption="Artikelgruppe" data-type="string" :allow-editing="false"
                 :visible="false"/>

      <template #availabilityCellTemplate="{ data }">
        <availabilityColorDivTemplate @click="availabliltyCellClicked(data)" :item-data="data" :index="extractIndex(data.column.dataField)"/>
      </template>

      <dx-column
          :data-field="getKwsField(currentCalendarWeek())"
          :caption="'KW ' + currentCalendarWeek()"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(1))"
          :caption="'KW ' +  currentCalendarWeek(1)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(2))"
          :caption="'KW ' + currentCalendarWeek(2)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(3))"
          :caption="'KW ' + currentCalendarWeek(3)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(4))"
          :caption="'KW ' + currentCalendarWeek(4)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(5))"
          :caption="'KW ' + currentCalendarWeek(5)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(6))"
          :caption="'KW ' + currentCalendarWeek(6)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />

      <dx-column
          :data-field="getKwsField(currentCalendarWeek(7))"
          :caption="'KW ' + currentCalendarWeek(7)"
          :data-type="string"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="availabilityCellTemplate"
      />


    </DxDataGrid>
  </div>
</template>

<script>

import availabilityColorDivTemplate from "../templates/availability-page-templates/availibilityColorDivTemplate.vue";
import {useAvailabilityStore} from "@/stores/AvailabilityStore";

import dxSwitch from "devextreme/ui/switch";
import DxButton from "devextreme-vue/button";
import DataSource from "devextreme/data/data_source";
import {DxSelectBox} from "devextreme-vue/select-box";
import {DxPopup} from 'devextreme-vue/popup';
import notify from "devextreme/ui/notify";
import {DxGroupItem, DxLabel} from "devextreme-vue/form";
import Utils from "../../utils/util";
import DxForm from 'devextreme-vue/form';
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxItem,
  DxPager,
  DxPaging,
  DxToolbar,
} from "devextreme-vue/data-grid";
import {useCompanyStore} from "@/stores/CompanyStore";

/**
 * Reference Keys für die UI Komponenten
 */

const popupComponent = "";
const orderDataGridComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    availabilityColorDivTemplate,
    DxDataGrid,
    DxPopup,
    DxGroupItem,
    DxForm,
    DxLabel,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxItem,
    DxPager,
    DxButton,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  setup() {
    const availabilityStore = useAvailabilityStore();
    const companyStore = useCompanyStore();

    return {availabilityStore, companyStore}
  },

  async mounted() {

    if (this.availabilityStore.originalArticleAvailable.length <= 0) {
      await this.availabilityStore.fetchArticle();
      await this.refreshGridAndData();
    }
  },

  data() {

    var isPopupVisible = false;
    var selectedColumnData = [];
    var parentData = {};
    const pageSizes = [5, 10, 20, 'all'];

    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "name",
      load: () => {
        return this.availabilityStore.displayGridData;
      },
    });

    const artikelGruppenSelectBoxDataSource = new DataSource({
      load: () => {
        return this.availabilityStore.articleGroups
      },
      byKey: () => {
        return this.availabilityStore.selectedArticleGroup
      },
    });

    /**
     * Current Calendar Week
     */

    const currentCalendarWeek = function (weeksToAddToCounter = null, onlyNumber = true, givenDate = null) {
      return Utils.getCalendarWeek(weeksToAddToCounter, onlyNumber, givenDate);
    };

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      popupComponent,
      selectedColumnData,
      isPopupVisible,
      pageSizes,
      parentData,
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      artikelGruppenSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsArtikelGruppenComponent,

      /**
       * Export UserData an die View Komponente
       */
      userData,

      /**
       * Export Additional Data to the view component
       */

      currentCalendarWeek,
    };
  },
  methods: {

    extractIndex(dataField) {
      return parseInt(dataField.split('.')[1], 10);
    },

    getKwsField(index) {
      return `kws.${index}`;
    },

    async saveClicked() {
      for (let i = 0; i < this.availabilityStore.showArticleAvailable.length; i++) {
        for (let j = 0; j < this.availabilityStore.showArticleAvailable[i].kws.length; j++) {
          if (this.availabilityStore.showArticleAvailable[i].kws[j].pK_ID === this.selectedColumnData.pK_ID) {

            console.log(this.selectedColumnData.adressgruppeKulturID)
            console.log(this.availabilityStore.showArticleAvailable[i].kws[j])

            //0 = grün
            //1 = rot

            var farbe = 0;

            if (this.showSelectedColumnData.availability === false) {
              farbe = 0;
            } else {
              farbe = 1;
            }

            try {
              await this.availabilityStore.updateArticle({
                adressgruppeKulturID: this.selectedColumnData.adressgruppeKulturID,
                farbe: farbe,
                kw: this.selectedColumnData.kw,
                mengeIst: this.showSelectedColumnData.mengeIst
              });
              await this.availabilityStore.fetchArticle();
              await this.orderDataGridInstance.refresh();
              this.popupInstance.toggle();

            } catch (e) {
              notify(e.response.message, "error", 2000)
            }
          }
        }
      }
    },

    hasPermissionToEditData() {
      return this.companyStore.company[0].kzVerfuegbarkeitEingabe === 1;
    },

    availabliltyCellClicked(data){
      if (!this.hasPermissionToEditData()) {
        return;
      }

      this.selectedColumnData = data.data.kws[data.columnIndex - 1];

      console.log("selectedColumnData", this.selectedColumnData);

      let verfuegbarkeit = this.selectedColumnData.farbe === "7FFF00";

      this.showSelectedColumnData = {mengeIst: this.selectedColumnData.mengeIst, availability: verfuegbarkeit, kw: this.selectedColumnData.kw};
      this.togglePopUp();
    },



    togglePopUp() {
      if (this.isPopupVisible === false) {
        this.isPopupVisible = true;
      } else {
        if (this.isPopupVisible === true) {
          this.isPopupVisible = false;
        }
      }
    },

    async refreshGridAndData() {

      try {
        if (this.availabilityStore.originalArticleAvailable.length <= 0) {
          notify("Daten können nicht geladen werden", "error", 5000);
          this.orderDataGridInstance.endCustomLoading();
        }
      } catch (error) {
        this.orderDataGridInstance.endCustomLoading();
        throw error;
      }
      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();

    },

    async refreshClicked() {
      try {
        this.orderDataGridInstance.beginCustomLoading();
        await this.availabilityStore.fetchArticle();
        this.orderDataGridInstance.refresh();
        this.orderDataGridInstance.endCustomLoading();

        notify("Daten aktualisiert!", "success", 3000);
      } catch (error) {
        notify(error, "error", 5000);
        this.orderDataGridInstance.endCustomLoading();
      }
    },

    selectBoxArtikelGruppenValueChangedEvent(item) {
      this.availabilityStore.selectedArticleGroup = item.value;
      this.orderDataGridInstance.refresh();
    },
  },
  computed: {
    dxSwitch() {
      return dxSwitch
    },
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    artikelGruppenSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsArtikelGruppenComponent.instance;
    },
    popupInstance: function () {
      return this.$refs.popupComponent.instance;
    },
  },
};
</script>