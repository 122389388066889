<template>
  <div>
    <h2 class="content-block">Profil</h2>

    <div class="content-block dx-card responsive-paddings">
      <dx-form
        id="form"
        label-location="top"
        :form-data="formData"
        :read-only="true"
        :colCountByScreen="colCountByScreen"
      />
      <div style="margin-top:20px">
        <DxButton text="Passwort zurücksetzen" type="normal" styling-mode="outlined" @click="navigateToResetPassword"/>
    </div>
    </div>
  </div>
</template>

<script>
import DxForm from "devextreme-vue/form";
import {useUserStore} from "@/stores/UserStore";
import DxButton from "devextreme-vue/button";
import router from "@/router";

export default {
  props: {
    picture: String
  },

  setup() {

  const userStore = useUserStore();

    const colCountByScreen = {
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4
    }

    return {
      colCountByScreen,
      userStore
    };
  },
  components: {
    DxForm,
    DxButton
  },

  methods:{
    navigateToResetPassword(){
      router.push("/reset-password");
    },
  },

  computed: {
    formData() {

      const store = this.userStore;

      return {"id": store.user.id, "Benutzername": store.user.username, "Vorname": store.user.firstName, "Nachname": store.user.lastName}
    }
  }
};
</script>

<style lang="scss">
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
</style>
