<template>

  <div v-if="this.itemData.eingabeBestellMenge == null || this.itemData.eingabeBestellMenge < 0">
    <div v-if="this.itemData.lockedEingabe" style="background: #FFB2B2; color:#FFB2B2; !important;">gesperrt</div>
    <div v-if="!this.itemData.lockedEingabe" style="background: #daffb2; color:#daffb2; !important;">entsperrt</div>
  </div>

  <div v-if="this.itemData.eingabeBestellMenge >= 0">
    {{this.itemData.eingabeBestellMenge}}
  </div>

</template>
<script>
import {useOrderStore} from "@/stores/OrderStore";

export default {
  mounted() {
    console.log(this.itemData);
  },

  setup() {
    const orderStore = useOrderStore();
    return {orderStore}
  },

  methods: {},

  props: {
    itemData: {
      type: Object,
      default: () => {
      },
    },
  }
}

</script>

<style>
.colorContainer {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
