import {defineStore} from "pinia";
import {useApplicationStore} from "@/stores/ApplicationStore";
import {useUserStore} from "@/stores/UserStore";
import axios from "axios";
import auth from "@/auth";

export const useIrrigationStore = defineStore('IrrigationStore', {

    state: () => {

        const irrigationArea: any[] = [];
        const lastUpdatedDate: any = null;
        const interValVar = null;

        return {
            interValVar,
            irrigationArea,
            lastUpdatedDate,
        };
    },


    actions: {

        async startIrrigation(beregnungsBereichId: any) {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            const state = {   zustand: 1,zeitstempel: new Date() };
            try{

            const data = await axios.post(applicationStore.getApiUrl + 'Beregnungsbereich/' + beregnungsBereichId, state,{
                headers: {
                    'Authorization': `token ${userStore.user.token}`
                }
            });
            }catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },

        async fetchIrrigationArea() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try {
                const data = await axios.get(
                    applicationStore.getApiUrl + 'Beregnungsbereich',
                    {
                        headers: {
                            'Authorization': `token ${userStore.user.token}`
                        }
                    }
                )
                console.log("data: ", data)
                this.irrigationArea = data.data;
                this.lastUpdatedDate = new Date().toLocaleString('de-DE');
            } catch (error: any) {
            if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                await auth.logOut();
            } else {
                throw error.response.status + " http error";
            }
        }
        },

        async stopIrrigation() {
            try {
                const applicationStore = useApplicationStore();

            const userStore = useUserStore();

                const data = await axios.post(applicationStore.getApiUrl + 'BeregnungStop', {},{
                headers: {
                    'Authorization': `token ${userStore.user.token}`
                }
            });
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },
    },

    getters: {
        getLastUpdatedDate(): string{
            if(this.lastUpdatedDate !== null){
                return this.lastUpdatedDate;
            } else {
                return "";
            }
        },

        displayGridData(): any[]{

            this.irrigationArea.sort(function(a, b) {
                return parseFloat(a.bezeichnung) - parseFloat(b.bezeichnung);
            });

            return this.irrigationArea;
        }
    },
    persist: true,
})
