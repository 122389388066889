export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Shop",
    icon: "cart",
    items: [
      {
        text: "Bestellen",
        authVariable: "kzKunde",
        path: "/bestellen"
      },
      {
        text: "Verfügbarkeit",
        authVariable: "kzKunde",
        path: "/verfügbarkeitKunde"
      }
    ]
  },
  {
    text: "Lieferant",
    icon: "runner",
    items: [
      {
        text: "Verfügbarkeit",
        path: "/verfügbarkeit",
        authVariable: "kzVerfuegbarkeit",
      },
      {
        text: "Abruf",
        authVariable: "kzLieferantSichtbar",
        path: "/lieferant"
      },
      {
        text: "Anbieten",
        authVariable: "kzLieferant",
        path: "/anbieten"
      },
    ]
  },
  {
    text: "Beregnung",
    path: "/beregnung",
    authVariable: "kzBeregnung",
    icon: "video"
  },
  {
    text: "Profil",
    icon: "card",
    items: [
      {
        text: "Einstellungen",
        path: "/profile"
      },
      {
        text: "Abmelden",
        path: "/logout"
      }
    ]
  }
];
