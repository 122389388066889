<template>
  <div>
    <h2 class="hide-mobile content-block">Lieferant</h2>

    <DxDataGrid
        class="dx-card wide-card irrigation-height"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
    >

      <DxToolbar>
        <DxItem location="before" locate-in-menu="auto">
          <DxSelectBox
              ref="selectBoxOptionsTourComponent"
              label="Tourenauswahl"
              width="230"
              labelMode="floating"
              placeholder="Wähle eine Tour"
              :value="supplierStore.selectedTour"
              :data-source="tourSelectBoxDataSource"
              :display-Expr="selectBoxTourDisplayExpression"
              @value-changed="selectBoxTourValueChangedEvent"
              item-template="tourSelectBoxTemplate"
          >
            <template #tourSelectBoxTemplate="{ data }">
              <tourSelectBoxItemTemplate :item-data="data"/>
            </template>
          </DxSelectBox>
        </DxItem>

        <DxItem locate-in-menu="auto">
          <div style="padding:10px;"> Letzte Aktualisierung <br> {{supplierStore.lastUpdatedDate}}</div>
        </DxItem>

        <DxItem location="after" locate-in-menu="auto">
          <DxButton text="Aktualisieren" type="normal" styling-mode="outlined" @click="refreshClicked"/>
        </DxItem>

        <DxItem location="after" locate-in-menu="auto">
          <DxButton text="PDF export" type="normal" styling-mode="outlined" @click="exportGrid"/>
        </DxItem>

      </DxToolbar>

      <DxEditing mode="cell" :allow-adding="false" :allow-deleting="false" :allow-updating="false"/>

      <dx-paging :page-size="10"/>
      <dx-pager :visible="true" :allowed-page-sizes="pageSizes"
                :show-navigation-buttons="true" :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="true"/>
      <DxScrolling mode="virtual"/>
      <DxSorting mode="single"/>

      <dx-column
          data-field="artikelbezeichnung"
          caption="Artikelbezeichnung"
          data-type="string"
          :allow-editing="false"
      />
      
      <dx-column
          data-field="mengeProVPE"
          caption="Inhalt (VPE)"
          data-type="string"
          :allow-editing="false"
          :calculate-cell-value="mengeVPECellFunction"
      />

      <dx-column
          data-field="bestellteMenge"
          caption="Bestellmenge"
          data-type="string"
          :allow-editing="false"
          :calculate-cell-value="bestellteMengeCellFunction"
      />

    </DxDataGrid>
  </div>
</template>

<script>

import dxSwitch from "devextreme/ui/switch";
import DataSource from "devextreme/data/data_source";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxToolbar,
  DxItem, DxScrolling,
} from "devextreme-vue/data-grid";
import {DxSorting} from "devextreme-vue/gantt";
import tourSelectBoxItemTemplate from "@/views/templates/order-page-templates/orderTourSelectBoxItemTemplate.vue";
import {DxSelectBox} from "devextreme-vue/select-box";
import {useSupplierStore} from "@/stores/SupplierStore";
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import Utils from "@/utils/util";
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";


/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxButton,
    DxSelectBox, tourSelectBoxItemTemplate,
    DxDataGrid,
    DxToolbar,
    DxColumn,
    DxPager,
    DxFilterRow,
    DxPaging,
    DxEditing,
    DxItem,
    DxScrolling,
    DxSorting
  },

  setup() {
    const supplierStore = useSupplierStore();

    return  {supplierStore}
  },

  async mounted() {
    await this.supplierStore.fetchTourData();

    this.supplierStore.selectedTour = this.supplierStore.originalTourData[0];
    await this.supplierStore.fetchArticlebyTourId();
    await this.refreshGridAndData();

  },

  data() {

    var selectedColumnData = [];
    var parentData = {};
    const pageSizes = [5, 10, 20, 'all'];

    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "artikelbezeichnung",
      load: () => {
        return this.supplierStore.article;
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};

    const currentCalendarWeek = function (weeksToAddToCounter = null, onlyNumber = false, givenDate = null) {
      return Utils.getCalendarWeek(weeksToAddToCounter, onlyNumber, givenDate);
    };

    const tourSelectBoxDataSource = new DataSource({
      key: "tourID",
      load: () => {
        return this.supplierStore.originalTourData;
      },
      byKey: () => {
        return this.supplierStore.selectedTour;
      },
    });

    const artikelGruppenSelectBoxDataSource = new DataSource({
      load: () => {
        return this.supplierStore.article;
      },
      byKey: () => {
        return this.supplierStore.article
      },
    });

    /**
     * Export der Variablen an die Applikation
     */

    return {
      currentCalendarWeek,
      tourSelectBoxDataSource,
      artikelGruppenSelectBoxDataSource,
      selectedColumnData,
      pageSizes,
      parentData,
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,


      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,

      /**
       * Export UserData an die View Komponente
       */
      userData,

    };
  },
  methods: {

    async refreshClicked(){

      try {
        await this.supplierStore.fetchArticlebyTourId();
        notify("Daten aktualisiert!", "success", 3000);
      } catch (error){
        notify("Fehler beim aktualisieren der Daten!", "error", 3000);
      }
    },

    mengeVPECellFunction(rowData) {
      return rowData.mengeProVPE + " " + rowData.einheit;
    },

    bestellteMengeCellFunction(rowData) {
      return rowData.bestellteMenge + " " + rowData.einheit;
    },

    exportGrid() {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: this.orderDataGridInstance
      }).then(() => {
        doc.save('Customers.pdf');
      });
    },

    async selectBoxTourValueChangedEvent(item) {
      this.orderDataGridInstance.beginCustomLoading();
      this.supplierStore.selectedTour = item.value;

      await this.supplierStore.fetchArticlebyTourId();
      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();
    },

    selectBoxTourDisplayExpression(item) {

      if (item !== null && item.datum) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }

        return (
            strWochentag +
            " " +
            item.datum.substring(8, 10) +
            "." +
            item.datum.substring(5, 7) +
            "." +
            item.datum.substring(0, 4) +
            " (KW " +
            this.currentCalendarWeek(null, true, item.datum) + ")"
        );
      }
    },

    async refreshGridAndData() {

      try {
        await this.supplierStore.fetchArticlebyTourId()
      } catch (error) {
        this.orderDataGridInstance.endCustomLoading();
        throw error;
      }
      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();

    },

  },
  computed: {
    dxSwitch() {
      return dxSwitch
    },
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
  },
};
</script>