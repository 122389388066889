<template>
  <div style="height: 20px">
    <div class="colorContainer" :style="getKw">{{ getMengeVerfuegbarInKw }}</div>
  </div>
</template>
<script>
export default {
  mounted() {},

  methods: {},

  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
    selectedProducer: {
      type: Number,
      default: () => {},
    },
  },
  computed: {
    getMengeVerfuegbarInKw() {
      var returnValue = null;
      switch (this.itemData.column.dataField) {
        case "kw1":
          returnValue = this.itemData.data.mengeVerfuegbarKWAktuell;
          break;

        case "kw2":
          returnValue = this.itemData.data.mengeVerfuegbarKWPlus1;
          break;

        case "kw3":
          returnValue = this.itemData.data.mengeVerfuegbarKWPlus2;
          break;

        default:
          returnValue = null;
          break;
      }

      return returnValue;
    },

    getKw() {
      var returnValue = null;
      switch (this.itemData.column.dataField) {
        case "kw1":
          returnValue = {
            background: "#" + this.itemData.data.farbeVerfuegbarKWAktuell,
          };
          break;

        case "kw2":
          returnValue = {
            background: "#" + this.itemData.data.farbeVerfuegbarKWPlus1,
          };
          break;

        case "kw3":
          returnValue = {
            background: "#" + this.itemData.data.farbeVerfuegbarKWPlus2,
          };
          break;

        default:
          returnValue = null;
          break;
      }

      return returnValue;
    },
  },
};
</script>

<style>
.colorContainer {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
