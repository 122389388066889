<template>
  <n-grid x-gap="0" :cols="8">
    <n-gi span="4"> {{ formatedDate }} </n-gi>
    <n-gi span="2">
      <div style="margin-left: 10px"> {{getCurrentCalendarWeek}}</div>
    </n-gi>
    <!--
    <n-gi span="2">
      <div v-if="isFinished" class="selectBoxStatusBeaconFinished"></div>
      <div v-if="isEdit" class="selectBoxStatusBeaconEdit"></div>
    </n-gi>
    -->
  </n-grid>

  <!--<div>{{ formatedDate }} </div>
  <div class="selectBoxStatusBeacon">2</div>-->
</template>
<script>
import { NGrid, NGi } from "naive-ui";
import Utils from "../../../utils/util";

export default {
  components: {
    NGrid,
    NGi,
  },

  mounted(){

  },

  methods: {
    formatDate(item) {
      if (item !== null) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }
        return strWochentag + " " + item.datum.substring(8, 10) + "." + item.datum.substring(5, 7) + "." + item.datum.substring(0, 4) + "";
      }
    },
  },

  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // computed property that auto-updates when the prop changes
    formatedDate() {
      return this.formatDate(this.itemData);
    },
    
    isFinished() {
      if (this.itemData.kzAngeboteVorhanden == 1) {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.itemData.kzAngeboteVorhanden == 2) {
        return true;
      } else {
        return false;
      }
    },
    getCurrentCalendarWeek() {
      
        return "KW " + Utils.getCalendarWeek(null, true, this.itemData.datum);
    }
  },
};
</script>

<style>
.selectBoxStatusBeaconFinished {
  width: 100%;
  height: 100%;
  background: green;
}

.selectBoxStatusBeaconEdit {
  width: 100%;
  height: 100%;
  background: yellow;
}
</style>
