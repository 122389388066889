import {defineStore} from "pinia";
import {useApplicationStore} from "@/stores/ApplicationStore";
import {useUserStore} from "@/stores/UserStore";
import Util from "@/utils/util";
import axios from "axios";
import auth from "@/auth";

export const useAvailabilityCustomerStore = defineStore('AvailabilityCustomerStore', {

    state: () => {

        const originalArticleAvailable: any[] = [];
        const showArticleAvailable: any[] = [];
        const articleGroups: any[] = [];
        const selectedArticleGroup: string = 'Alle Artikel';
        const lastUpdatedDate: any = null;

        return {
            selectedArticleGroup,
            originalArticleAvailable,
            showArticleAvailable,
            articleGroups,
            lastUpdatedDate,
        };
    },


    actions: {

        async fetchArticle() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try{
                const data = await axios.get(
                    applicationStore.getApiUrl + 'vwShopLWVerfuegbarkeitKunde',
                    {
                        headers: {
                            'Authorization': `token ${userStore.user.token}`
                        }
                    }
                )
                this.originalArticleAvailable = data.data;
                this.mapArticle();
                this.lastUpdatedDate = new Date().toLocaleString('de-DE');
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },

        mapArticle() {
            // Map article by articleID

            /*
            Array mit allen Artikeln (Name und ID) anlegen
            dann originaldaten durchgehgen und dem jeweiligen artikel die kalenderwoche zuordnen
      
             */

            const articleData: any[] = [];

            articleData.push({
                name: this.originalArticleAvailable[0].bezeichnung,
                artikelID: this.originalArticleAvailable[0].artikelID,
                artikelgruppe: this.originalArticleAvailable[0].artikelgruppe,
                benutzerShopID: this.originalArticleAvailable[0].benutzerShopID,
                kws: [],
            })

            for (let i = 0; i < this.originalArticleAvailable.length; i++) {
                if (articleData) {

                    let boolArtikelExistiertInArray = false;

                    //Checken ob im articleData Array schon der Artikel hinterlegt wurde
                    for (let j = 0; j < articleData.length; j++) {
                        if (articleData[j].artikelID === this.originalArticleAvailable[i].artikelID) {
                            boolArtikelExistiertInArray = true;
                        }
                    }
                    if (!boolArtikelExistiertInArray) {
                        articleData.push({
                            name: this.originalArticleAvailable[i].bezeichnung,
                            artikelID: this.originalArticleAvailable[i].artikelID,
                            artikelgruppe: this.originalArticleAvailable[i].artikelgruppe,
                            benutzerShopID: this.originalArticleAvailable[i].benutzerShopID,
                            kws: []
                        })
                    }
                }
            }

            for (let x = 0; x < this.originalArticleAvailable.length; x++) {
                for (let i = 0; i < articleData.length; i++) {
                    if (articleData[i].artikelID === this.originalArticleAvailable[x].artikelID) {
                        articleData[i].kws.push({
                            adressgruppeKulturID: this.originalArticleAvailable[x].adressgruppeKulturID,
                            farbe: this.originalArticleAvailable[x].farbe,
                            jahr: this.originalArticleAvailable[x].jahr,
                            kw: this.originalArticleAvailable[x].kw,
                            kwJahr: this.originalArticleAvailable[x].kwJahr,
                            mengeIst: this.originalArticleAvailable[x].mengeIst,
                            mengeSoll: this.originalArticleAvailable[x].mengeSoll,
                            pK_ID: this.originalArticleAvailable[x].pK_ID,
                        });
                    }
                }
            }

            this.originalArticleAvailable = articleData;
            this.showArticleAvailable = articleData;

            this.setArticleGroups();
        },

        setArticleGroups() {
            const articleGroups = ["Alle Artikel"];
            for (let i = 0; i < this.originalArticleAvailable.length; i++) {
                if (!articleGroups.includes(this.originalArticleAvailable[i].artikelgruppe)) {
                    articleGroups.push(this.originalArticleAvailable[i].artikelgruppe);
                }
            }
            this.articleGroups = articleGroups;
        },
    },

    getters: {
        getLastUpdatedDate(): string{
            if(this.lastUpdatedDate !== null){
                return this.lastUpdatedDate;
            } else {
                return "";
            }
        },

        displayGridData(): any[]{
            return Util.filterGridNachArtikelGruppeUndTour(this.originalArticleAvailable, this.selectedArticleGroup, null);
        }
    },
    persist: true,
})
