<template>
  <div>
    <h2 class="hide-mobile content-block">Beregnung</h2>

    <DxDataGrid
        class="dx-card wide-card irrigation-height"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
        @cell-click="cellClickedEventHandler"
    >

      <DxToolbar>
        <DxItem location="after" locate-in-menu="auto">
          <DxButton :width="280" text="Beregnung stoppen" type="danger" styling-mode="outlined" @click="stopClicked"/>
        </DxItem>
        <DxItem location="after" locate-in-menu="auto">
          <DxButton :width="150" text="Aktualisieren" type="normal" styling-mode="outlined" @click="refreshClicked"/>
        </DxItem>
      </DxToolbar>

      <DxEditing mode="cell" :allow-adding="false" :allow-deleting="false" :allow-updating="false"/>

      <dx-paging :page-size="10"/>
      <dx-pager :visible="true" :allowed-page-sizes="pageSizes"
                :show-navigation-buttons="true" :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="true"/>
      <DxScrolling mode="virtual"/>


      <DxColumn data-field="bezeichnung" caption="Bezeichnung" data-type="string" allowFiltering="true" sort-order="asc" :filter-operations="['startswith']" :default-filter-operation="'startswith'" width="160"
                :allow-editing="false"/>

        <template #irrigationActionButtonTemplate="{ data }">
        <irrigationActionButtonTemplate :item-data="data"/>
      </template>
      <DxSorting mode="single"/>


      <dx-column
          data-field="zustand"
          caption="Zustand"
          data-type="string"
          :calculate-display-value="stateDisplayExpression"
          allow-editing="false"
          allowFiltering="false"
      />


      <dx-column
          data-field="Aktion"
          :allow-editing="false"
          :allowFiltering="false"
          cell-template="irrigationActionButtonTemplate"
      />

      <dx-column
          data-field="nummer"
          caption="Nummer"
          data-type="string"
          allow-editing="false"
          allowFiltering="false"
      />

      <dx-column
          :visible="false"
          data-field="pk_ID"
          caption="Nummer"
          data-type="string"
          allow-editing="false"
          allowFiltering="false"
      />

      <dx-column
          data-field="ventilnummer"
          caption="Ventilnummer"
          :visible="false"
          data-type="string"
          allow-editing="false"
          allowFiltering="false"
      />

      <dx-column
          data-field="zeitstempel"
          caption="Zeitstempel"
          data-type="string"
          :calculate-display-value="dateDisplayExpression"
          allow-editing="false"
          allowFiltering="false"
      />
    </DxDataGrid>
  </div>
</template>

<script>

import irrigationActionButtonTemplate from "./templates/irrigation-page-templates/irrigationActionButton.vue"
import {useIrrigationStore} from "@/stores/IrrigationStore";
import dxSwitch from "devextreme/ui/switch";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";

import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxToolbar,
  DxItem, DxScrolling,
} from "devextreme-vue/data-grid";
import {useCompanyStore} from "@/stores/CompanyStore";
import {DxSorting} from "devextreme-vue/gantt";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";


/**
 * Export der Vue Komponente
 */

export default {
  components: {
    irrigationActionButtonTemplate,
    DxDataGrid,
    DxToolbar,
    DxButton,
    DxColumn,
    DxPager,
    DxFilterRow,
    DxPaging,
    DxEditing,
    DxItem,
    DxScrolling,
    DxSorting
  },

  setup() {
    const irrigationStore = useIrrigationStore();
    const companyStore = useCompanyStore();

    return {irrigationStore, companyStore}
  },

  async mounted() {

    await this.irrigationStore.fetchIrrigationArea();

    clearInterval(this.irrigationStore.interValVar);
    this.irrigationStore.interValVar = window.setInterval(this.callIrrigationViewOnInterval, 3000);

    if (this.irrigationStore.irrigationArea.length <= 0) {
      await this.irrigationStore.fetchIrrigationArea();
      await this.refreshGridAndData();
    }
  },

  data() {

    var selectedColumnData = [];
    var parentData = {};
    const pageSizes = [5, 10, 20, 'all'];

    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "bezeichnung",
      load: () => {
        return this.irrigationStore.displayGridData;
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      selectedColumnData,
      pageSizes,
      parentData,
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,


      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,

      /**
       * Export UserData an die View Komponente
       */
      userData,

    };
  },
  methods: {


    callIrrigationViewOnInterval(){
      console.log("update irrigation view...");
      this.irrigationStore.fetchIrrigationArea();
      this.refreshGridAndData();
    },

    stateDisplayExpression(item){
      let retVal = "Aus";

      if(item.zustand === 0){
        retVal = "Aus";
      } else {
        retVal = "An";
      }

      return retVal;
    },

    dateDisplayExpression(item){
      return new Date(item.zeitstempel).toLocaleString('de-DE');
    },

    async refreshGridAndData() {

      try {
        if (this.irrigationStore.irrigationArea.length <= 0) {
          notify("Daten können nicht geladen werden", "error", 5000);
          this.orderDataGridInstance.endCustomLoading();
        }
      } catch (error) {
        this.orderDataGridInstance.endCustomLoading();
        throw error;
      }
      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();

    },

    async refreshClicked() {
      try {
        this.orderDataGridInstance.beginCustomLoading();
        await this.irrigationStore.fetchIrrigationArea();
        console.log(this.irrigationStore.fetchIrrigationArea())
        this.orderDataGridInstance.refresh();
        this.orderDataGridInstance.endCustomLoading();

        notify("Daten aktualisiert!", "success", 3000);
      } catch (error) {
        notify(error, "error", 5000);
        this.orderDataGridInstance.endCustomLoading();
      }
    },
    async stopClicked() {
      try {
        this.orderDataGridInstance.beginCustomLoading();
        await this.irrigationStore.stopIrrigation();
        this.orderDataGridInstance.refresh();
        this.orderDataGridInstance.endCustomLoading();

        notify("Daten aktualisiert!", "success", 3000);
      } catch (error) {
        notify(error, "error", 5000);
        this.orderDataGridInstance.endCustomLoading();
      }
    },

  },
  computed: {
    dxSwitch() {
      return dxSwitch
    },
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
  },
};
</script>