import {useApplicationStore} from "@/stores/ApplicationStore";
import {defineStore} from "pinia";
import axios from "axios"
import {UserModel} from "@/models/user.model";
import auth from "@/auth";

export const useUserStore = defineStore('UserStore', {

    state: () => {

        const user: UserModel = {id: null, token: '', username: '', firstName: '', lastName: '', passwordReset: false}

        return {
            user
        };
    },

    actions: {
        async authenticateUser(username: string, password: string) {

            const applicationStore = useApplicationStore();
            try {
                const data = await axios.post(applicationStore.getApiUrl + 'users/authenticate', {
                    username: username, password: password,
                });
                this.user = data.data;
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error;
                }
            }
        },
        async changePassword(password: string) {


            console.log("change password user", this.user)

            const applicationStore = useApplicationStore();
            // eslint-disable-next-line no-useless-catch
            try {
                const data = await axios.post(applicationStore.getApiUrl + 'spShopKennwortAendern', {
                    kennwort: password,
                }, {
                    headers: {
                        'Authorization': `token ${this.user.token}`
                    }
                });
                return data.data;
            } catch (error: any) {
                throw error;
            }
        }
    }, persist: true,
});
