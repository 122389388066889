<template>
  <div class="center">
    <DxCheckBox :value="isCheckBoxAlreadyChecked" :disabled="isDisabled" @value-changed="checkBoxChanged"/>
  </div>
</template>
<script>
import {DxCheckBox} from "devextreme-vue/check-box";
import {useOrderStore} from "@/stores/OrderStore";

export default {

  setup(){

    const orderStore = useOrderStore();

    return {orderStore}
    },

  components: {DxCheckBox},

  methods: {
    checkBoxChanged(e) {
      if (e.value === true) {
        for (let i = 0; i < this.orderStore.articleAndProducerData.length; i++) {
          if (this.orderStore.articleAndProducerData[i].pK_ID === this.itemData.pK_ID) {
            this.orderStore.articleAndProducerData[i].eingabeBestellMenge = 1;
            this.orderStore.articleAndProducerData[i].halbeVPEChecked = 1;
            this.orderStore.articleAndProducerData[i].verpackungsfaktorID = 1;
            this.orderStore.articleAndProducerData[i].lockedEingabe = true;
            this.$emit("update-grid-on-price-update");
            break;
          }
        }
      } else {
        for (let i = 0; i < this.orderStore.articleAndProducerData.length; i++) {
          if (this.orderStore.articleAndProducerData[i].pK_ID === this.itemData.pK_ID) {
            this.orderStore.articleAndProducerData[i].eingabeBestellMenge = 0;
            this.orderStore.articleAndProducerData[i].halbeVPEChecked = 0;
            this.orderStore.articleAndProducerData[i].verpackungsfaktorID = null;
            this.orderStore.articleAndProducerData[i].lockedEingabe = false;


            for (let x = 0; x < this.orderStore.originalArticleAndProducerData.length; x++) {
              if (this.orderStore.originalArticleAndProducerData[x].pK_ID === this.itemData.pK_ID) {


                if(this.orderStore.articleAndProducerData[i].mengeProVPE !== this.orderStore.originalArticleAndProducerData[x].mengeProVPE){
                  this.orderStore.articleAndProducerData[i].mengeProVPE = this.orderStore.articleAndProducerData[i].mengeProVPE * 2;
                }
              }
            }

            this.$emit("update-grid-on-price-update");
            break;
          }
        }
      }
    },
  },

  props: {
    itemData: {
      type: Object,
      default: () => {
      },
    },
    parentData: {
      type: Object,
      default: () => {
      },
    },
    rowData: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    isCheckBoxAlreadyChecked() {

      if (this.itemData.halbeVPEChecked === 1 || this.itemData.verpackungsfaktorID === 1) {
        return true;
      } else {
        return false;
      }
    },
    isDisabled() {

      if(this.itemData.halbeVPEChecked !== 1 && this.itemData.lockedEingabe){
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.center {
  text-align: center;
}
</style>
