<template>
  <div>
    <div class="content-block">
      <h2>Anbieten</h2>
    </div>

    <DxDataGrid
        class="dx-card wide-card"
        ref="orderDataGridComponent"
        :data-source="orderGridDataSource"
        :show-borders="true"
        :focused-row-enabled="false"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :no-data-text="gridNoDataText"
        :allowColumnReordering="false"
        :allowColumnResizing="true"
        :row-alternation-enabled="true"
        :show-column-lines="true"
        :show-row-lines="true"
        @cell-prepared="onCellPrepared"
        @row-validating="onCellValidating"
    >
      <DxFilterRow :visible="true"/>
      <DxHeaderFilter :visible="true"/>

      <DxToolbar>
        <DxItem location="before" locate-in-menu="auto">
          <DxSelectBox
              ref="selectBoxOptionsTourComponent"
              label="Tourenauswahl"
              width="230"
              labelMode="floating"
              placeholder="Wähle eine Tour"
              :value="offerStore.selectedTour"
              :data-source="tourSelectBoxDataSource"
              :display-Expr="selectBoxTourDisplayExpression"
              @value-changed="selectBoxTourValueChangedEvent"
              item-template="tourSelectBoxTemplate">

            <template #tourSelectBoxTemplate="{ data }">
              <tourSelectBoxItemTemplate :item-data="data"/>
            </template>

          </DxSelectBox>
        </DxItem>

        <DxItem location="before" locate-in-menu="auto">
          <DxSelectBox
              ref="selectBoxOptionsArtikelGruppenComponent"
              width="180"
              label="Artikelgruppe"
              labelMode="floating"
              placeholder="Wähle eine Artikelgruppe"
              :value="offerStore.selectedArticleGroupData"
              :data-source="artikelGruppenSelectBoxDataSource"
              @value-changed="selectBoxArtikelGruppenValueChangedEvent"
          />
        </DxItem>

        <DxItem locate-in-menu="auto">
        <div style="padding:10px;"> Letzte Aktualisierung <br> {{offerStore.lastUpdatedDate}}</div>
        </DxItem>

        <DxItem location="after" locate-in-menu="auto">
          <DxButton :width="150" text="Aktualisieren" type="normal" styling-mode="outlined" @click="refreshClicked"/>
        </DxItem>

        <DxItem locate-in-menu="auto">
          <DxSearchPanel :visible="true" :width="240" placeholder="Suche..."/>
        </DxItem>

        <DxItem locate-in-menu="auto">
          <DxButton width="200" ref="buttonWarenkorb" :text="basketText" type="success" styling-mode="outlined"
                    @click="warenkorbToggle"/>
        </DxItem>

        <DxItem locate-in-menu="auto">
          <DxButton width="180" text="Anbieten" type="danger" styling-mode="outlined" @click="finishOffer"/>
        </DxItem>
      </DxToolbar>

      <DxEditing mode="cell" :allow-adding="false" :allow-deleting="false" :allow-updating="true"/>

      <dx-paging :page-size="10"/>
      <dx-pager :visible="true" :allowed-page-sizes="pageSizes"
                :show-navigation-buttons="true" :show-page-size-selector="true" :show-info="true"/>
      <dx-filter-row :visible="true"/>

      <dx-column data-field="artikelnummer" caption="Artikelnummer" data-type="string" :allow-editing="false"
                 :visible="false"/>

      <dx-column data-field="artikelbezeichnung" caption="Bezeichnung" width="280" data-type="string" :allow-editing="false"/>

      <dx-column data-field="artikelgruppe" caption="Artikelgruppe" data-type="string" :allow-editing="false"
                 :visible="false"/>

      <dx-column data-field="artikelID" caption="ArtikelID" data-type="number" :allow-editing="false" :visible="false"/>

      <dx-column data-field="adresseID" caption="AdresseID" data-type="string" :allow-editing="false" :visible="false"/>

      <dx-column
          data-field="bestellteMenge"
          caption="Bestellte VPE"
          :data-type="string"
          :allow-editing="false"
          :calculate-cell-value="mengeVerfuegbarCellFunction"
      />

      <dx-column data-field="mengeVPE" caption="Inhalt (VPE)" data-type="number" :allow-editing="false"
                 :calculate-cell-value="mengeVPECellFunction"/>

      <DxColumn data-field="eingabeAnbietenMenge" caption="Eingabe (VPE)" data-type="number" :allow-editing="true"
                :allow-updating="true"
                :validation-rules="eingabeBestellMengeValidationRules">


      </DxColumn>

      <dx-column
          data-field="eingabebestellmenge"
          caption="Angebotsmenge"
          data-type="string"
          :allow-editing="false"
          :calculate-cell-value="eingabebestellmengeCellFunction"
      />

      <DxColumn data-field="anbietenMenge" caption="Angebotene VPE" data-type="number"
                :allow-editing="false"></DxColumn>

      <dx-column data-field="preis" caption="Einzelpreis" data-type="string" :allow-editing="false"
                 :calculate-cell-value="preisCellFunction"/>

      <DxSummary :calculate-custom-summary="sumPrice">
        <DxTotalItem name="sumPrice" :display-format="roundSumme" summary-type="custom" show-in-column="preis"/>
      </DxSummary>
    </DxDataGrid>
  </div>
</template>

<script>

import tourSelectBoxItemTemplate from "../templates/offer-page-templates/offerTourSelectBoxItemTemplate.vue";

import DxButton from "devextreme-vue/button";
import DataSource from "devextreme/data/data_source";
import {DxSelectBox} from "devextreme-vue/select-box";
import notify from "devextreme/ui/notify";
import Utils from "../../utils/util";
import auth from "../../auth";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxItem,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxToolbar,
  DxEditing,
  DxSummary,
  DxTotalItem,
} from "devextreme-vue/data-grid";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useOfferStore} from "@/stores/OfferStore";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const selectBoxStatusComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    tourSelectBoxItemTemplate,
    DxDataGrid,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxItem,
    DxPager,
    DxSearchPanel,
    DxButton,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
    DxSummary,
    DxTotalItem,
  },


  setup() {
    const offerStore = useOfferStore();
    const companyStore = useCompanyStore();

    return {offerStore, companyStore}
  },

  async mounted() {

    let newData = false;

    if (this.offerStore.originalTourData.length <= 0) {
      await this.offerStore.fetchTourData();
      newData = true;
    }

    if (this.offerStore.articleData.length <= 0) {
      this.offerStore.selectedTour = this.offerStore.originalTourData[0];
      await this.offerStore.fetchArticleData();
    }

    if (newData) {
      this.offerStore.selectedTour = this.offerStore.originalTourData[0];
      await this.refreshGridAndData();
    }
  },

  data() {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const pageSizes = [5, 10, 20, 'all'];

    const eingabeBestellMengeValidationRules = [
      {
        type: 'pattern',
        pattern: "^-?\\d+$",
        message: 'Nur ganze Zahlen sind erlaubt'
      },
      {
        type: 'pattern',
        pattern: "^[0-9]\\d*$",
        message: 'Nur positive Zahlen erlaubt'
      }
    ];

    /**
     * DataSource Objekte für die UI Komponenten
     */

    const currentCalendarWeek = function (weeksToAddToCounter = null, onlyNumber = false, givenDate = null) {
      return Utils.getCalendarWeek(weeksToAddToCounter, onlyNumber, givenDate);
    };

    const orderGridDataSource = new DataSource({
      key: "pK_ID",
      load: () => {
        return this.offerStore.displayGridData;
      },
      update: (key, values) => {

        for (let z = 0; z < this.offerStore.originalTourData.length; z++) {
          if (this.offerStore.originalTourData[z].tourID === this.tourSelectBoxInstance.option("value")) {
            this.offerStore.originalTourData[z].kzAngeboteVorhanden = 2;
          }
        }
        this.tourSelectBoxInstance.repaint();

        for (let index = 0; index < this.offerStore.articleData.length; index++) {
          if (this.offerStore.articleData[index].pK_ID === key) {

            //Validation: anbietenMenge darf nicht kleiner als BestellteMenge sein.
            if (values.eingabeAnbietenMenge < this.offerStore.articleData[index].bestellteMenge) {
              notify("Artikel die Bereits bestellt wurden können nicht zurückgezogen werden", "warning", 5000);
              return false;
            }

            // Setze Bestellmenge im Grid

            this.offerStore.articleData[index].eingabeAnbietenMenge = values.eingabeAnbietenMenge;
            this.orderDataGridInstance.refresh();

            break;
          }
        }
      },
    });

    const tourSelectBoxDataSource = new DataSource({
      load: () => {
        return this.offerStore.originalTourData;
      },
      byKey: () => {
        return this.offerStore.selectedTour;
      },
    });

    const artikelGruppenSelectBoxDataSource = new DataSource({
      load: () => {
        return this.offerStore.originalArticleGroupData;
      },
      byKey: () => {
        return this.offerStore.selectedArticleGroupData;
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      pageSizes,
      delay,
      currentCalendarWeek,

      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      tourSelectBoxDataSource,
      artikelGruppenSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      selectBoxStatusComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
      userData,

      /**
       * Validation Rules
       */

      eingabeBestellMengeValidationRules
    };
  },
  methods: {

    onCellValidating(e) {
      if(e.brokenRules.length > 0){
        e.isValid = false;
        e.cancel = true;
      }
    },

    async refreshGridAndData() {
      try {
        if (this.offerStore.originalTourData.length <= 0 || this.offerStore.articleData.length <= 0) {
          notify("Daten können nicht geladen werden", "error", 5000);
          this.orderDataGridInstance.endCustomLoading();
        }
      } catch (error) {
        this.orderDataGridInstance.endCustomLoading();
        throw error;
      }

      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();
    },

    async refreshClicked() {
      try {
        this.orderDataGridInstance.beginCustomLoading();
        await this.offerStore.fetchTourData();
        await this.offerStore.fetchArticleData();
        this.orderDataGridInstance.refresh();
        this.orderDataGridInstance.endCustomLoading();

        notify("Daten aktualisiert!", "success", 3000);
      } catch (error) {
        notify(error, "error", 5000);
        this.orderDataGridInstance.endCustomLoading();
      }
    },

    sumPrice(options) {
      if (options.value !== undefined) {
        if (options.name === "sumPrice") {
          if (options.summaryProcess === "start") {
            options.totalValue = 0;
          } else if (options.summaryProcess === "calculate") {

            const preis = parseFloat(options.value.preis);
            const eingabeAnbietenMenge = parseFloat(options.value.eingabeAnbietenMenge)
            const mengeVPE = parseFloat(options.value.mengeVPE)

            if(preis !== null && eingabeAnbietenMenge !== null && mengeVPE !== null && !Number.isNaN(preis) && !Number.isNaN(eingabeAnbietenMenge && !Number.isNaN(mengeVPE))){

              const multiply = preis * (eingabeAnbietenMenge * mengeVPE)
              options.totalValue += multiply;

            }

            if (options.totalValue == null) {
              options.totalValue = 0;
            }
          }
        }
      }
      if(options.totalValue === undefined || Number.isNaN(options.totalValue)) {
        options.totalValue = 0;
      }
    },

    roundSumme(e) {
      return "Summe: " + parseFloat(e).toFixed(2) + " €";
    },

    /**
     * Cell Prepared Styles
     */

    onCellPrepared(e) {
      if (e.rowType == "data" && e.column.dataField == "anbietenMenge" && e.data.anbietenMenge > 0) {
        e.cellElement.classList.add("cellColorAngeboten");
      }

      if (e.rowType == "data" && e.column.dataField == "eingabeAnbietenMenge") {
        e.cellElement.classList.add("eingabeUnderline");
      }

      if (e.rowType == "data" && e.column.dataField == "eingabeAnbietenMenge" && e.data.eingabeAnbietenMenge > 0) {
        e.cellElement.classList.add("cellColorInBearbeitung");
      }
    },

    /**
     * Bestellen & Warenkorb
     */

    disableUIComponents() {
      this.artikelGruppenSelectBoxInstance.option("disabled", true);
    },

    enableUIComponents() {
      this.artikelGruppenSelectBoxInstance.option("disabled", false);
    },

    warenkorbToggle() {
      if (this.offerStore.basketToggle === false) {
        this.disableUIComponents();

        this.offerStore.selectedArticleGroupData = 'Alle Artikel'

        this.orderDataGridInstance.refresh();

        this.offerStore.basketToggle = true;
      } else {

        this.enableUIComponents();
        this.orderDataGridInstance.refresh();

        this.offerStore.basketToggle = false;
      }
    },

    async finishOffer() {
      //Check ob in der Tour daten eingegeben wurden
      this.orderDataGridInstance.beginCustomLoading();
      var offerArticel = false;
      for (var i = 0; i < this.offerStore.articleData.length; i++) {
        if (this.offerStore.articleData[i].eingabeAnbietenMenge >= 0) {
          offerArticel = true;
          break;
        }
      }

      if (offerArticel === false) {
        notify("Keine Artikel zum anbieten ausgewählt.", "warning", 3000);
        this.orderDataGridInstance.endCustomLoading();
        return;
      }

      const offerArticelObject = [];

      for (let x = 0; x < this.offerStore.articleData.length; x++) {
        if (this.offerStore.articleData[x].eingabeAnbietenMenge >= 0) {
          offerArticelObject.push({
            pK_ID: this.offerStore.articleData[x].pK_ID,
            tourID: this.offerStore.articleData[x].tourID,
            adresseID: this.offerStore.articleData[x].adresseID,
            artikelID: this.offerStore.articleData[x].artikelID,
            menge: this.offerStore.articleData[x].eingabeAnbietenMenge,
          });
        }
      }

      try {
         for (const element of offerArticelObject) {
          if (element.tourID === this.tourSelectBoxInstance.option("value").tourID) {
            await this.offerStore.postOffer(element);
          }
        }

        await this.delay(1000);
        await this.refreshClicked();
        this.orderDataGridInstance.endCustomLoading();

      } catch (error) {
        notify("Fehler: " + error, "error", 3000);
      }
    },

    /**
     * Display Expression Events
     */

    selectBoxTourDisplayExpression(item) {

      if (item !== null && item.datum) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }
        return strWochentag + " " + item.datum.substring(8, 10) + "." + item.datum.substring(5, 7) + "." + item.datum.substring(0, 4) + " (KW " +
            this.currentCalendarWeek(null, true, item.datum) + ")";
      }
    },

    /**
     * Value Change Events
     */

    selectBoxArtikelGruppenValueChangedEvent(item) {
      this.offerStore.selectedArticleGroupData = item.value;
      this.orderDataGridInstance.refresh();
    },

    async selectBoxTourValueChangedEvent(item) {
      this.orderDataGridInstance.beginCustomLoading();
      this.offerStore.selectedTour = item.value;

      console.log("fetch ", item.value)

      await this.offerStore.fetchArticleData();
      this.orderDataGridInstance.refresh();
      this.orderDataGridInstance.endCustomLoading();
    },

    /**
     * Get User Data
     */

    async getUserData() {
      await auth.getUser().then((e) => {
        this.userData = e.data;
      });
    },

    mengeVerfuegbarCellFunction(rowData) {

      if (rowData.bestellteMenge < 0) {
        return "0 " + rowData.einheit;
      } else if (rowData.maxAnzeigeMenge < rowData.bestellteMenge && rowData.maxAnzeigeMenge > 0) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.bestellteMenge + " " + rowData.einheit;
      }
    },
    mengeVPECellFunction(rowData) {


      return rowData.mengeVPE + " " + rowData.einheit;
    },
    eingabebestellmengeCellFunction(rowData) {



      if (rowData.eingabeAnbietenMenge > 0 && rowData.mengeVPE > 0) {
        return rowData.eingabeAnbietenMenge * rowData.mengeVPE + " " + rowData.einheit;
      } else if (typeof rowData.eingabeAnbietenMenge === "undefined" || rowData.eingabeAnbietenMenge === 0) {
        return "0" + " " + rowData.einheit;
      } else {
        return "0 " + rowData.einheit;
      }
    },
    preisCellFunction(rowData) {



      let einzelpreis = parseFloat(rowData.preis);
      return String(einzelpreis.toFixed(2)) + "€";
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
    artikelGruppenSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsArtikelGruppenComponent.instance;
    },
    buttonWarenkorbInstance: function () {
      return this.$refs.buttonWarenkorb.instance;
    },
    basketText(){
      if(this.offerStore.basketToggle){
        return 'Artikelübersicht';
      } else {
        return 'Warenkorbansicht';
      }
    },
    gridNoDataText(){
      if(this.offerStore.basketToggle){
        return "Sie haben noch keine Artikel angeboten";
      } else {
        return "Keine Artikel gefunden!";
      }
    }
  },
};
</script>

<style>
.cellColorAngeboten {
  background-color: #dcecca !important;
}

.cellColorInBearbeitung {
  background-color: #ffffe0 !important;
}
</style>
