import {defineStore} from "pinia";

export const useApplicationStore = defineStore('ApplicationStore', {

    state: () => {

        const PROD_API_URL: string = 'https://shop.regional-bio.de/api/';
        const DEV_API_URL: string = 'https://test.regional-bio.de/api/'

        return {
            PROD_API_URL,
            DEV_API_URL,
        };
    },

    actions: {},

    getters: {
        getApiUrl(): string {
            if (window.location.href.includes("shop.regional-bio.de")) {
                return this.PROD_API_URL;
            } else {
                return this.DEV_API_URL
            }
        },
    },
});
