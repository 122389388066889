<template>
  <div style="height: 100%">
    <div class="colorContainer" :style="getKw">{{ getMengeVerfuegbarInKw }} </div>
  </div>
</template>
<script>


import {useCompanyStore} from "@/stores/CompanyStore";


export default {
  mounted() {
  },

  props: {
    itemData: {
      type: Object,
      default: () => {
      },
    },
    index: {
      type: Number,
      default: () => {
      },
    },
  },
  computed: {
    getMengeVerfuegbarInKw() {

      const companyStore = useCompanyStore();
      if(companyStore.company[0].kzVerfuegbarkeitEingabe !== 1){
        return "";
      }

      var returnValue = null;
      returnValue = this.itemData.data.kws[this.index -1]?.mengeIst;

      return returnValue;
    },

    getKw() {

      var returnValue = null;
      returnValue = {
        background: "#" + this.itemData.data.kws[this.index -1]?.farbe,
      };

      return returnValue;
    },
  },
};
</script>

<style>
.colorContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: 20px;
}
</style>
