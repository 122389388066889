import {useUserStore} from "@/stores/UserStore";
import {useCompanyStore} from "@/stores/CompanyStore";
import {useAvailabilityStore} from "@/stores/AvailabilityStore";
import {useOfferStore} from "@/stores/OfferStore";
import {useOrderStore} from "@/stores/OrderStore";

export default {
  loggedIn() {
    const userStore = useUserStore();
    return userStore.user.id != null;
  },

  needsPasswordReset(){
    const userStore = useUserStore();
    return userStore.user.passwordReset;
  },

  async logIn(email: string, password: string) {

    const userStore = useUserStore();
    try {
      await userStore.authenticateUser(email, password);
    } catch (e: any){

      return {
        isOk: false,
        message: "Fehler: " + e.response.data.message,
      };
    }

    console.log(userStore.user);

    const companyStore = useCompanyStore();
    try {
      await companyStore.fetchCompanys();
    } catch (e){
      return {
        isOk: false,
        message: "Fehler: " + e,
      };
    }

    console.log(companyStore.company[0]);

    return {
      isOk: true,
      data: null,
    };
  },

  async logOut() {
    const userStore = useUserStore();
    const availabilityStore = useAvailabilityStore();
    const offerStore = useOfferStore();
    const orderStore = useOrderStore();
    const companyStore = useCompanyStore();
    offerStore.$reset();
    orderStore.$reset();
    companyStore.$reset();
    availabilityStore.$reset();
    userStore.$reset()
    location.reload();
  },

  async resetPassword() {
    
  },

  async changePassword(email: string, recoveryCode: string) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  },
};
