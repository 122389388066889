<template>
  <dx-button @click="startIrrigation()" :style="getIrrigationStateStyle" class="actionButton" type="normal" styling-mode="outlined">{{getButtonText}}</dx-button>
</template>
<script>

import DxButton from "devextreme-vue/button";
import {useIrrigationStore} from "@/stores/IrrigationStore";
import notify from "devextreme/ui/notify";

export default {

  setup(){
    const irrigationStore = useIrrigationStore();
    return {irrigationStore}
  },

  mounted() {
  },

  props: {
    itemData: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {

    getButtonText(){

      console.log(this.itemData.data.zustand)

      if(this.itemData.data.zustand === 0){
        return "Beregnung starten";
      } else {
        return "Beregnung gestartet";
      }
    },
    getIrrigationStateStyle(){

      if(this.itemData.data.zustand === 0){
        return null;
      } else {
        return {background: "lightgreen"}
      }
    },

  },
  methods:{
    startIrrigation(){
      console.log(this.itemData.data.pK_ID);
      this.irrigationStore.startIrrigation(this.itemData.data.pK_ID);

      notify("Beregnungsanfrage gesendet", "success", 5000);
    }
  },
  components:{
    DxButton
  }
};
</script>

<style>
.actionButton{
  width: 100%;

}
</style>
