import {useApplicationStore} from "@/stores/ApplicationStore";
import {useUserStore} from "@/stores/UserStore";
import {defineStore} from "pinia";
import axios from "axios"
import Util from "@/utils/util";
import auth from "@/auth";

export const useOrderStore = defineStore('OrderStore', {

    state: () => {

        const originalProducerData: any[] = [];
        const originalTourData: any[] = [];
        const originalArticleGroupData: any[] = [];
        const selectedArticleGroupData: string = 'Alle Artikel';
        const selectedTour: TourModel = {tourID: null}
        const basketToggle: boolean = false;
        const lastUpdatedDate: any = null;


        const articleAndProducerData: any[] = [];
        const originalArticleAndProducerData: any[] = [];

        return {
            originalProducerData,
            originalTourData,
            selectedArticleGroupData,
            originalArticleGroupData,
            selectedTour,
            basketToggle,
            lastUpdatedDate,
            articleAndProducerData,
            originalArticleAndProducerData
        };
    },

    actions: {

        async fetchArticleAndProducerData() {
            const applicationStore = useApplicationStore();
            const userStore = useUserStore();

            if (this.selectedTour === null) {
                throw new Error('no tour selected')
            }
            try{
                const data = await axios.get(applicationStore.getApiUrl + 'vwShopLWBestellen/' + this.selectedTour.tourID, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.articleAndProducerData = data.data
                this.originalArticleAndProducerData = Util.deepCopy(data.data);
                this.lastUpdatedDate = new Date().toLocaleString('de-DE');
                this.setArticleGroups();
                console.log("article and producer", data.data);

            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },

        async fetchTourData() {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try{
                const data = await axios.get(applicationStore.getApiUrl + 'vwShopTour', {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    }
                })
                this.originalTourData = data.data;
                console.log("TourID", this.originalTourData[0]);

            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        },


        async postOrder(body: any) {

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try{
                const data = await axios.post(applicationStore.getApiUrl + 'spShopLWBestellen', {
                    tourID: body.tourID,
                    adresseID: body.adresseID,
                    artikelID: body.artikelID,
                    menge: body.menge,
                    verpackungsfaktorID: body.verpackungsfaktorID,
                    adresseIDLieferant: body.adresseIDLieferant,
                }, {
                    headers: {
                        'Authorization': `token ${userStore.user.token}`
                    },
                });

            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }

        },


        setArticleGroups() {
            const articleGroups = ["Alle Artikel"];
            for (let i = 0; i < this.articleAndProducerData.length; i++) {
                if (!articleGroups.includes(this.articleAndProducerData[i].artikelgruppe)) {
                    articleGroups.push(this.articleAndProducerData[i].artikelgruppe);
                }
            }
            this.originalArticleGroupData = articleGroups;
        },
    },

    getters: {
        getLastUpdatedDate(): string {
            if (this.lastUpdatedDate !== null) {
                return this.lastUpdatedDate;
            } else {
                return "";
            }
        },

        displayGridData(): any[] {

            if (this.articleAndProducerData.length <= 0) {
                return [];
            }

            let ret = Util.filterGridNachArtikelGruppeUndTour(this.articleAndProducerData, this.selectedArticleGroupData, null);

            console.log("basketStatus", this.basketToggle);

            if (this.basketToggle) {
                ret = ret.filter(function (el) {
                    return el.bestellmenge > 0 || el.eingabeBestellMenge > 0;
                });
            }
            return ret;
        },
    },

    persist: true
});
