import auth from "./auth";
import {createRouter, createWebHashHistory, NavigationGuardNext, RouteLocationNormalized} from "vue-router";

import Home from "./views/home-page.vue";
import Profile from "./views/profile-page.vue";
import Offer from "./views/shop/offer-page.vue";
import SupplierPage from "./views/shop/supplier-page.vue";
import Order from "./views/shop/order-page.vue";
import Availability from "./views/shop/availability-page.vue";
import AvailabilityCustomer from "./views/shop/availability-page-customer.vue";
import Irrigation from "./views/irrigation-page.vue";
import ResetPassword from "./views/reset-password-form.vue";
import defaultLayout from "./layouts/side-nav-outer-toolbar.vue";
import simpleLayout from "./layouts/single-card.vue";

function loadView(view: any) {
    return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

// @ts-ignore
const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/verfügbarkeit",
      name: "verfügbarkeit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Availability
    },
    {
      path: "/verfügbarkeitKunde",
      name: "verfügbarkeitKunde",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AvailabilityCustomer
    },
    {
      path: "/beregnung",
      name: "beregnung",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Irrigation
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/anbieten",
      name: "offer",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Offer
    },
    {
      path: "/lieferant",
      name: "supplier",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SupplierPage
    },
    {
      path: "/Bestellen",
      name: "order",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Order
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Anmelden"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: true,
        layout: simpleLayout,
        title: "Passwort zurücksetzen",
      },
      component: ResetPassword
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }, 
  ],
  history: createWebHashHistory()
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {

    if (to.name === "login-form" && auth.loggedIn() && !auth.needsPasswordReset()) {
        next({ name: "home" });
    } else if (to.name === "reset-password" && auth.needsPasswordReset()) {
        // Allow access to the "reset-password" route.
        next();
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.loggedIn()) {
            next({
                name: "login-form",
            });
        } else if (auth.needsPasswordReset()) {
            next({ name: "reset-password" })
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
