import {useApplicationStore} from "@/stores/ApplicationStore";
import {useUserStore} from "@/stores/UserStore";
import {defineStore} from "pinia";
import axios from "axios"
import {CompanyModel} from "@/models/company.model";
import auth from "@/auth";

export const useCompanyStore = defineStore('CompanyStore', {

    state: () => {

        const company: CompanyModel[] = [];

        return {
            company,
        };
    },

    actions: {
        async fetchCompanys() {

            if(this.company.length > 0){
                return;
            }

            const applicationStore = useApplicationStore();
            const userStore = useUserStore();
            try{
                const data = await axios.get(
                    applicationStore.getApiUrl + 'vwShopAdresse',
                    {
                        headers: {
                            'Authorization': `token ${userStore.user.token}`
                        }
                    }
                )
                this.company = data.data;
            } catch (error: any) {
                if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
                    await auth.logOut();
                } else {
                    throw error.response.status + " http error";
                }
            }
        }
    },

    getters: {},

    persist: true,
})
