import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import {createApp} from "vue";
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from "./router";

// @ts-ignore
import App from "./App";
import appInfo from "./app-info";

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App);


app.use(pinia).use(router, VueAxios, axios);
app.config.globalProperties.$appInfo = appInfo;
app.mount('#app');
