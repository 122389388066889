export default {
    getCalendarWeek(weeksToAddToCounter = null, onlyNumber = false, givenDate = null) {
        let date = new Date();

        if (givenDate != null) {
            date = new Date(givenDate);
        }
        const currentThursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);
        const yearOfThursday = currentThursday.getFullYear();
        const firstThursday = new Date(new Date(yearOfThursday, 0, 4).getTime() + (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000);
        let weekNumber = Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7);

        if (weeksToAddToCounter != null) {
            weekNumber += weeksToAddToCounter;
        }

        if (onlyNumber) {
            return weekNumber;
        }

        return "KW " + weekNumber + " (VPE)";
    },

    deepCopy(obj: any) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        const temp = obj.constructor(); // gibt uns ein neues Objekt vom selben Typ
        for (const key in obj) {
            temp[key] = this.deepCopy(obj[key]);
        }
        return temp;
    },


    filterGridNachArtikelGruppeUndTour(arrayToFilter: any[], artikelGruppe: string | null = null, tour: number | null = null) {
        if (tour != null) {
            console.log("Action filter nach Tour", tour);

            arrayToFilter = arrayToFilter.filter(function (el) {
                return el.tourID === tour;
            });
        }

        if (artikelGruppe != null) {
            console.log("Action filter nach Artikelgruppe", artikelGruppe);
            arrayToFilter = arrayToFilter.filter(function (el) {
                return el.artikelgruppe === artikelGruppe || artikelGruppe === "Alle Artikel";
            });
        }

        arrayToFilter.sort((a, b) => (a.sortierung < b.sortierung ? -1 : 1));
        return arrayToFilter;
    },
};
