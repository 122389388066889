<template>
  <DxSelectBox
    width="100%"
    labelMode="floating"
    placeholder=""
    display-Expr="produzent"
    :value="getSelectedProducerById"
    :data-source="itemData"
    :readOnly="isProducerOnlyBioHofOrAlreadyOrdered"
    @value-changed="producerChanged"
  />
</template>
<script>
import { DxSelectBox } from "devextreme-vue/select-box";
import {useOrderStore} from "@/stores/OrderStore";

export default {
  components: { DxSelectBox },

  setup(){
    const orderStore = useOrderStore();

    return {orderStore}
  },

  mounted() {},

  methods: {
    producerChanged(e) {
      for (let i = 0; i < this.orderStore.articleAndProducerData.length; i++) {
        if (this.orderStore.articleAndProducerData[i].artikelID === e.value.artikelID) {
          this.orderStore.articleAndProducerData[i].selectedProducerID = e.value.pK_ID;
          break;
        }
      }
    },
  },

  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
    parentData: {
      type: Object,
      default: () => {},
    },
    selectedProducer: {
      type: Number,
      default: () => {},
    },
  },
  computed: {

    isProducerOnlyBioHofOrAlreadyOrdered() {

      const result = this.itemData.filter(function (el) {
        return el.kzProduzentHatBestellung === 1;
      });

      if (this.itemData.length <= 1 || result.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getSelectedProducerById() {

      const result = this.itemData.filter(function (el) {
        return el.kzProduzentHatBestellung === 1;
      });

      if(result.length > 0){
        return result[0];
      }

      var producerItem = null;

      if (this.itemData.length >= 1) {
        producerItem = this.itemData[0];

        for (let i = 0; i < this.itemData.length; i++) {
          if (this.itemData[i].pK_ID === this.selectedProducer) {
            producerItem = this.itemData[i];
            break;
          }
        }
      }

      return producerItem;
    },
  },
};
</script>
